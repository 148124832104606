import { Task } from '@augusthealth/models/com/august/protos/task'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { useUserContext } from '@shared/contexts/UserContext'
import { getFullName } from '@shared/utils/humanName'
import { printFileFromIFrame } from '@shared/utils/iframePrint'
import { tw, twx } from '@shared/utils/tailwind'
import {
  areExternalSignersNext,
  isPendingSignatures,
  isUserAmongExternals,
  taskTitle,
} from '@shared/utils/task'
import RPBreadcrumbs from '@app/components/Breadcrumbs/RPBreadcrumbs'
import Sign from '@app/components/Prospects/Sign'
import { fileUrlForTask } from '@app/components/SignatureFlow/InProgress'
import PersonContext from '@app/contexts/PersonContext'
import useBlobData from '@app/hooks/useBlobData'
import usePerson from '@app/hooks/usePerson'
import useProfileImage from '@app/hooks/useProfileImage'
import { useTasksForPerson as useTasks } from '@app/hooks/useTasks'
import ExternalSignerModal, {
  SigningTask,
} from '@app/pages/ExternalSigner/ExternalSignerModal'

export default function ExternalSignerPage(props: { match: any }) {
  const urlIds = useParams<{ orgId: string; facilityId: string; id: string }>()

  const { user } = useUserContext()
  const { person } = usePerson({
    initialData: urlIds,
    dependencies: [urlIds.id],
  })
  const { tasks, refreshTasks } = useTasks({ person })
  const { imageURL } = useProfileImage({ person })
  const [signingTask, setSigningTask] = useState<SigningTask>({
    tag: 'First time',
  })
  const [showModal, setShowModal] = useState<boolean>(false)

  const tasksForExternalSigner =
    tasks.tag !== 'Loading'
      ? tasks.value
          .filter(isPendingSignatures)
          .filter(areExternalSignersNext)
          .filter((t) => {
            return isUserAmongExternals(user, t)
          })
      : []

  useEffect(() => {
    signingTask.tag !== 'First time' && setSigningTask({ tag: 'First time' })
  }, [urlIds.id])

  useEffect(() => {
    if (signingTask.tag === 'First time' && tasksForExternalSigner.length > 0) {
      setSigningTask({ tag: 'Task to sign', task: tasksForExternalSigner[0] })
      setShowModal(false)
    } else if (
      signingTask.tag === 'First time' &&
      tasksForExternalSigner.length === 0 &&
      tasks.tag === 'Complete'
    ) {
      setSigningTask({ tag: 'Nothing to sign' })
      setShowModal(true)
    }
  }, [tasks])

  const fileUrl =
    signingTask.tag === 'Task to sign' && person.tag === 'Complete'
      ? fileUrlForTask(signingTask.task, person.value)
      : null
  const blobData = useBlobData(fileUrl)

  let url: string | undefined
  let filename: string | undefined
  const data = blobData.tag === 'Complete' && blobData.value
  if (data) {
    url = data.url
    filename = data.filename
  }

  if (
    person.tag === 'Loading' ||
    tasks.tag === 'Loading' ||
    imageURL.tag === 'Loading'
  ) {
    return <div>Loading...</div>
  }

  const cardOnClick = (task: Task) => {
    setSigningTask({ tag: 'Task to sign', task: task })
    setShowModal(false)
  }

  const onSign = () => {
    void refreshTasks()
    setShowModal(true)
    setSigningTask({ tag: 'Successful sign' })
  }

  const onClose = () => {
    setShowModal(true)
    setSigningTask({ tag: 'Canceled sign' })
  }

  const downloadBtnStyles = twx(
    'inline-flex items-center justify-center uppercase transition-all disabled:opacity-50 h-[40px] rounded-[6px] px-[16px] py-[14px] text-[11px] font-bold leading-[12px] border border-secondary bg-transparent text-secondary hover:bg-scrim-white hover:outline hover:outline-1 hover:outline-secondary hover:no-underline'
  )

  return (
    <PersonContext.Provider
      value={{
        person: person.value,
        tasks: tasks.value,
        setTasks: throwError,
        setPerson: throwError,
        profileImageURL: imageURL.value,
        setProfileImageURL: throwError,
        isPersonLoading: false,
        setIsPersonLoading: throwError,
        signInPersonPopupTask: undefined,
        setSignInPersonPopupTask: throwError,
      }}
    >
      <div className={'m-auto mt-[24px] w-[80vw]'}>
        <img alt="" src="/svg/rebrand_logotype.svg" />
      </div>
      <div
        className={
          'm-auto mt-[16px] flex w-[80vw] min-w-[512px] flex-row items-end gap-[8px]'
        }
      >
        <RPBreadcrumbs
          className={tw`pb-0 [&_a:hover]:text-gray-07 [&_a]:cursor-default`}
          person={person.value}
          tasks={tasks.value}
          currentPage={{
            orgId: urlIds.orgId,
            personId: urlIds.id,
            facilityId: urlIds.facilityId,
            tag: 'Person',
            tab: { tag: 'SignDocuments' },
          }}
          user={user}
        />
      </div>
      {signingTask.tag === 'Task to sign' && (
        <div className={'m-auto mt-[8px] w-[80vw] min-w-[512px]'}>
          <div
            className={tw`mb-[24px] mt-8 flex w-full items-center justify-between`}
          >
            <div className={'pl-[8px] text-[24px] font-[500]'}>
              {taskTitle(signingTask.task)} for {getFullName(person.value.name)}
            </div>
            {url && (
              <div className="flex gap-[12px]">
                <a className={downloadBtnStyles} href={url} download={filename}>
                  <i className="fas fa-arrow-alt-to-bottom mr-[8px]" />
                  Download
                </a>
                <AsyncIconButton
                  buttonSize={'medium'}
                  buttonStyle={'secondary-outline'}
                  initialIcon={'fa-print'}
                  onClick={() => printFileFromIFrame(url!)}
                >
                  Print
                </AsyncIconButton>
              </div>
            )}
          </div>
          <Sign
            email={user.email!}
            fullscreen={true}
            match={props.match}
            title={'Title'}
            taskId={signingTask.task.id}
            onCloseBehavior={{
              tag: 'Close HelloSign UI',
              onSign: onSign,
              onClose,
            }}
          />
        </div>
      )}
      {showModal &&
        (signingTask.tag === 'Nothing to sign' ||
          signingTask.tag === 'Successful sign' ||
          signingTask.tag === 'Canceled sign') && (
          <ExternalSignerModal
            signableTasks={tasksForExternalSigner}
            taskCardOnClick={cardOnClick}
            lastSigningState={signingTask.tag}
            hideModal={() => setShowModal(false)}
          />
        )}
    </PersonContext.Provider>
  )
}

function throwError() {
  throw new Error('Not implemented')
}
