import { useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Icon from '@shared/components/Icon'
import LinkButton from '@shared/components/LinkButton'
import { tw } from '@shared/utils/tailwind'
import { RHFSwitch } from '@app/components/Switch'
import DayForAutoPayModal from './DayForAutoPayModal'

export default function AutoPayCard() {
  const [autopayData, setAutopayData] = useState(false)
  const [isDayForAutoPayModalOpen, setIsDayForAutoPayModalOpen] =
    useState(false)

  if (autopayData === false) {
    return (
      <div
        className={tw`h-[130px] min-h-0 w-[560px] animate-fade-in rounded-lg bg-white p-2 shadow-card`}
      >
        <div
          className={tw`flex h-full flex-col items-center justify-center bg-gray-13`}
        >
          <Icon
            className={tw`mt-[13px] text-2xl font-semibold text-primary`}
            variant="solid"
            name="bank"
          />

          <AsyncIconButton
            buttonStyle="link"
            isLoading={false}
            onClick={() => setAutopayData(true)}
            className={tw`text-sm font-medium normal-case underline`}
          >
            Set up autopay
          </AsyncIconButton>
        </div>
      </div>
    )
  }

  return (
    <>
      <DayForAutoPayModal
        onClose={() => setIsDayForAutoPayModalOpen(false)}
        isOpen={isDayForAutoPayModalOpen}
        currentDayOfMonth={17}
      />
      <div
        className={tw`min-h-0 w-[560px] animate-fade-in rounded-lg bg-white p-6 shadow-card`}
      >
        <div className={tw`flex flex-col divide-y-2 pb-4`}>
          <div className={tw`flex items-start justify-start gap-4`}>
            <Icon
              className={tw`text-4xl font-semibold text-gray-04`}
              name="fa-brands fa-cc-visa"
            />
            <div className={tw`flex flex-col`}>
              <span className={tw`text-sm font-semibold text-gray-04`}>
                USAA Federal Savings Bank
              </span>
              <span className={tw`text-sm font-medium text-gray-07`}>
                Ending in 1234
              </span>
            </div>
          </div>
        </div>

        <hr className="border-1 border-gray-10" />

        <div className={tw`flex items-center justify-start gap-4 py-4`}>
          <RHFSwitch
            label=""
            onChange={() => setAutopayData(false)}
            switchSize="small"
          />
          <span className={tw`text-sm font-medium text-gray-04`}>
            Auto pay on the{' '}
            <AsyncIconButton
              buttonStyle="link"
              isLoading={false}
              onClick={() => setIsDayForAutoPayModalOpen(true)}
              className={tw`text-sm font-medium normal-case underline`}
            >
              17th of the month
            </AsyncIconButton>
          </span>
        </div>

        <hr className="border-1 border-gray-10" />

        <div className={tw`flex items-center justify-start gap-6 pt-4`}>
          <LinkButton
            icon="pencil"
            href=""
            onClick={() => setAutopayData(false)}
            buttonStyle="link"
            className={tw`h-6 text-sm font-medium capitalize text-gray-07`}
          >
            Update payment info
          </LinkButton>

          <LinkButton
            icon="trash"
            href=""
            onClick={() => setAutopayData(false)}
            buttonStyle="link"
            className={tw`h-6 text-sm font-medium capitalize text-gray-07`}
          >
            Remove payment method
          </LinkButton>
        </div>
      </div>
    </>
  )
}
