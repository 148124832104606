import { Task } from '@augusthealth/models/com/august/protos/task'
import { useContext } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { incompleteTask } from '@shared/utils/task'
import { sendReminder } from '@app/api/tasks'
import ConfirmModal from '@app/components/ConfirmModal'
import EditDiet from '@app/components/Diet/EditDiet'
import ProfileForm from '@app/components/Layouts/PersonNavbarLayout/ProfileForm'
import EditAmbulatoryStatus from '@app/components/Prospects/Details/CareHighlights/EditCareHighlights/EditAmbulatoryStatus'
import GeneralUploader from '@app/pages/Documents/Uploader'

export type ModalType =
  | 'profile'
  | 'diet'
  | 'ambulatory'
  | 'polst'
  | '602'
  | 'rp-reminder'

type ModalConfiguration =
  | {
      modalType: Extract<ModalType, 'rp-reminder'>
      setShowReminderNotice: React.Dispatch<React.SetStateAction<boolean>>
    }
  | { modalType?: Exclude<ModalType, 'rp-reminder'> }

export default function ResidentModalProducer({
  person,
  onClose,
  onUpdate,
  modalConfiguration,
  tasks,
}: {
  person: Person
  onClose: () => void
  onUpdate: () => Promise<void>
  modalConfiguration: ModalConfiguration
  tasks: Task[]
}) {
  const { setError } = useContext(GlobalContext)

  const incompletePolstTask = incompleteTask(tasks, DataType.DATA_TYPE_POLST)
  const incomplete602Task = incompleteTask(
    tasks,
    DataType.DATA_TYPE_CA_FORM_602
  )

  if (!modalConfiguration.modalType) {
    return null
  }

  const sendReminderToRP = async () => {
    try {
      await sendReminder({
        orgId: person.orgId!,
        facilityId: person.facilityId!,
        personId: person.id!,
      })
    } catch (e) {
      setError(e)
    }
  }
  const onComplete = async (updated: boolean) => {
    if (updated) {
      await onUpdate()
    }
    onClose()
  }

  switch (modalConfiguration.modalType) {
    case 'rp-reminder':
      return (
        <ConfirmModal
          title="The RP has not yet signed."
          content="The Service Plan needs to be signed by the RP before it is considered complete."
          confirmButtonConfig={{
            onClick: async () => {
              await sendReminderToRP()
              modalConfiguration.setShowReminderNotice(true)
              await onComplete(true)
            },
          }}
          denyButtonConfig={{
            onClick: async () => {
              await onComplete(false)
            },
          }}
        />
      )
    case 'ambulatory':
      return <EditAmbulatoryStatus person={person} onClose={onComplete} />
    case 'polst':
      return (
        <GeneralUploader
          person={person}
          initialType={DataType.DATA_TYPE_POLST}
          tasks={incompletePolstTask ? [incompletePolstTask] : []}
          onUpdate={onUpdate}
          onClose={onClose}
        />
      )
    case '602':
      return (
        <GeneralUploader
          person={person}
          initialType={DataType.DATA_TYPE_CA_FORM_602}
          tasks={incomplete602Task ? [incomplete602Task] : []}
          onUpdate={onUpdate}
          onClose={onClose}
        />
      )
    case 'diet':
      return <EditDiet person={person} onClose={onComplete} />
    case 'profile':
      return (
        <ProfileForm
          person={person}
          doneFn={(p) => onComplete(!!p)}
          handleError={setError}
        />
      )
    default:
      return null
  }
}
