import { AsyncIconButton } from '@shared/components/AsyncButton'
import { tw } from '@shared/utils/tailwind'
import AutoPayCard from './AutoPayCard'
import BalanceCard from './BalanceCard'

export default function Overview() {
  return (
    <>
      <div className={tw`flex items-center justify-between`}>
        <h2
          className={tw`mb-0 text-2xl font-medium leading-9 text-secondary-02`}
        >
          Overview
        </h2>

        <AsyncIconButton
          buttonStyle="primary-fill"
          isLoading={false}
          initialIcon="square-dollar"
          onClick={() => {}}
        >
          Make One Time Payment
        </AsyncIconButton>
      </div>
      <div
        className={tw`mb-6 mt-[26px] flex w-full items-start justify-between gap-4 text-sm`}
      >
        <BalanceCard />
        <AutoPayCard />
      </div>
    </>
  )
}
