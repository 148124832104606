import { tw } from '@shared/utils/tailwind'
import Overview from './Overview'
import TransactionsTable from './TransactionsTable'

export default function PayerBilling() {
  return (
    <div className={tw`mt-8 flex flex-col gap-6`}>
      <div className={tw`h-[308px]`}>
        <Overview />
      </div>
      <TransactionsTable />
    </div>
  )
}
