import { Controller, useForm } from 'react-hook-form'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { LabelAboveInput } from '@shared/components/Labels'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { DaysOfMonthOptions } from '@shared/constants/timing'
import { tw } from '@shared/utils/tailwind'
import { ButtonGroup } from '@app/components/ButtonGroup'

type Props = {
  isOpen: boolean
  onClose: () => void
  currentDayOfMonth: number | null
}

export default function DayForAutoPayModal(props: Props) {
  const {
    handleSubmit,
    control,
    formState,
    formState: { disabled },
  } = useForm({
    defaultValues: {
      dayOfMonth: props.currentDayOfMonth,
    },
  })

  async function onSubmit(formValues = {}) {
    console.log('submitted form values:', formValues)
  }

  return (
    <Modal
      contentClassName={tw`min-w-[380px]`}
      id="day-for-auto-pay-modal"
      open={props.isOpen}
      onClose={props.onClose}
    >
      <ModalTitle
        className={tw`leading-0 border-0 pb-0 text-2xl font-medium text-secondary-02`}
      >
        Day for Auto Pay
      </ModalTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="dayOfMonth"
          render={({ field: { onChange, value } }) => (
            <>
              <LabelAboveInput
                className={tw`mb-0 ml-auto w-fit`}
                htmlFor="dayOfMonth"
              />
              <ButtonGroup
                type="Single"
                options={DaysOfMonthOptions}
                onChange={(e: OptionTypeBase<number>[]) =>
                  onChange(e.map((opt) => opt.value))
                }
                prependTestId="days-of-month"
                buttonClassName={tw`flex h-[40px] w-[40px] items-center justify-center`}
                containerClassName={tw` grid grid-cols-[repeat(7,40px)] grid-rows-4 gap-1.5`}
                chosen={DaysOfMonthOptions.filter(
                  (dayOfMonth) => value === dayOfMonth.value
                )}
              />
            </>
          )}
        />

        <div className={tw`mt-8`}>
          <AnimatedPopupFormFooter
            yesBtn={{ label: 'Save', props: { disabled } }}
            noBtn={{ action: () => void props.onClose() }}
            formState={formState}
          />
        </div>
      </form>
    </Modal>
  )
}
