import { useEffect, useState } from 'react'
import {
  FetchFacilityCareProgress,
  fetchFacilityCareProgress,
} from '@shared/api/careapp'
import { FacilityProgressRoutines } from '@shared/types/careapp'
import { AugustError } from '@shared/utils/error'
import { AsyncResult, LOADING } from '@shared/utils/loading'

export default function useFacilityCareProgress(
  props: FetchFacilityCareProgress
) {
  const [facilityCareProgress, setFacilityCareProgress] =
    useState<AsyncResult<FacilityProgressRoutines, AugustError>>(LOADING)

  useEffect(() => {
    setFacilityCareProgress(LOADING)
    fetchFacilityCareProgress(props)
      .then((facilityProgressRoutines) => {
        setFacilityCareProgress({
          tag: 'Complete',
          value: facilityProgressRoutines,
        })
      })
      .catch((err: AugustError) =>
        setFacilityCareProgress({ tag: 'Error', value: err })
      )
  }, [
    props.facility.orgId,
    props.facility.id,
    props.date,
    props.careGroupId,
    props.shiftId,
  ])

  return { facilityCareProgress }
}
