import { RadioGroup } from '@augusthealth/august-frontend-form-elements'
import { func, string } from 'prop-types'
import { useEffect, useState } from 'react'
import {
  RadioButtonGroupInGenerator,
  RadioCardGroupInGenerator,
  RadioInputGroupInGenerator,
} from '@app/components/formElements/RadioGroup'
import FormImage from '@app/components/Prospects/Forms/CustomForm/FormImage'
import { SharedRadioButtonGroup } from '@app/components/Prospects/Forms/CustomForm/SharedRadioButtonGroup'
import StaticText from '@app/components/Prospects/Forms/CustomForm/StaticText'
import DateOfBirth from '../DateOfBirth'
import { displayDateError } from '../DateOfBirth/utils'
import AddressAutocomplete from '../formElements/AddressAutocomplete'
import AmbulatoryRadioGroup from '../formElements/AmbulatoryRadioGroup'
import ConditionList from '../formElements/ConditionList'
import ContactDropdown from '../formElements/ContactDropdown'
import RpContactDropdown from '../formElements/ContactDropdown/RpContactDropdown'
import ContactDropdownList from '../formElements/ContactDropdownList'
import ContactEmail from '../formElements/ContactEmail'
import DietSelect from '../formElements/DietSelect/DietSelectInGenerator'
import DietSelectList from '../formElements/DietSelect/DietSelectInGenerator/List'
import ElementHolder from '../formElements/ElementHolder'
import HashTextInput, { HashTextarea } from '../formElements/HashTextInput'
import LanguageDropdown from '../formElements/Language/Dropdown/LanguageDropdownInGenerator'
import LanguageDropdownList from '../formElements/Language/List/ListInGenerator'
import HashList from '../formElements/List/HashList'
import RpAddressAutoComplete from '../formElements/RpAddressAutoComplete'
import SSN from '../formElements/SSN/SsnInGenerator'
import MoneyInput from '../MoneyInput/MoneyInGenerator'
import { CheckboxGroup } from '../Prospects/Forms/CustomForm/checkboxGroup'
import { CustomContactDropdown } from '../Prospects/Forms/CustomForm/customContactDropdown'
import { CustomContactDropdownList } from '../Prospects/Forms/CustomForm/customContactDropdownList'
import RoomBedSelect from '../RoomBedSelect/SelectInGenerator'
import StyledSelect from './SelectInGenerator'

function YesNoButtons(props) {
  return (
    <RadioGroup
      {...props}
      options={[{ value: 'Yes' }, { value: 'No' }]}
      type="buttons"
    />
  )
}

function Buttons(props) {
  return <RadioGroup {...props} type="buttons" />
}

export function mapExtraCompName(c) {
  const { type = 'text' } = c || {}
  if (type === 'contact_dropdown') {
    return 'ContactDropdown'
  } else if (type === 'custom_contact_dropdown') {
    return 'CustomContactDropdown'
  } else if (type === 'contact_dropdown_list') {
    return 'ContactDropdownList'
  } else if (type === 'custom_contact_dropdown_list') {
    return 'CustomContactDropdownList'
  } else if (type === 'ambulatory_radio_group') {
    return 'AmbulatoryRadioGroup'
  } else if (type === 'address_autocomplete') {
    return 'AddressAutocomplete'
  } else if (type === 'language_dropdown') {
    return 'LanguageDropdown'
  } else if (type === 'language_dropdown_list') {
    return 'LanguageDropdownList'
  } else if (type === 'birthday') {
    return 'DateOfBirthWrapper'
  } else if (type === 'hash_list') {
    return 'HashList'
  } else if (type === 'hash_text' || type === 'hash_textarea') {
    return 'HashTextInput'
  } else if (type === 'money') {
    return 'MoneyInput'
  } else if (type === 'diet_dropdown') {
    return 'DietSelect'
  } else if (type === 'diet_dropdown_list') {
    return 'DietSelectList'
  } else if (type === 'condition_list') {
    return 'ConditionList'
  } else if (type === 'contact_email') {
    return 'ContactEmail'
  }

  // Allow to specify React Class name directy, see uiComponentsMap below
  if (type.charAt(0) === type.charAt(0).toUpperCase()) {
    return type
  }
}

function DateOfBirthWrapper(props) {
  const {
    title,
    name,
    value,
    onUpdate,
    alertMessage: defaultAlertMessage,
  } = props
  const [error, setError] = useState()
  const [alertMessage, setAlertMessage] = useState()

  useEffect(() => {
    setAlertMessage(displayDateError(error))
  }, [error, value])

  return (
    <ElementHolder
      title={title}
      alertMessage={alertMessage || defaultAlertMessage}
    >
      <DateOfBirth
        value={value}
        onUpdate={(value) => onUpdate(value, name)}
        name={name}
        setError={setError}
        acceptDateObject
      />
    </ElementHolder>
  )
}

DateOfBirthWrapper.propTypes = {
  title: string,
  name: string,
  value: string,
  onUpdate: func,
  alertMessage: string,
}

export const uiComponentsMap = {
  AddressAutocomplete,
  AmbulatoryRadioGroup,
  ContactDropdown,
  RpContactDropdown,
  CustomContactDropdown,
  ContactDropdownList,
  CustomContactDropdownList,
  RadioButtonGroup: RadioButtonGroupInGenerator,
  RadioCardGroup: RadioCardGroupInGenerator,
  RadioInputGroup: RadioInputGroupInGenerator,
  LanguageDropdown,
  LanguageDropdownList,
  DateOfBirthWrapper,
  HashList,
  HashTextarea,
  HashTextInput,
  MoneyInput,
  DietSelect,
  DietSelectList,
  ConditionList,
  ContactEmail,
  RoomBedSelect,
  RpAddressAutoComplete,
  StyledSelect,
  TextInput: HashTextInput, // overwrite the original TextInput
  YesNoButtons,
  Buttons,
  CheckboxGroup,
  SSN,
  StaticText,
  FormImage,
  SharedRadioButtonGroup,
}
