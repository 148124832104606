import React from 'react'
import Icon from '@shared/components/Icon'
import LinkButton from '@shared/components/LinkButton'
import { tw } from '@shared/utils/tailwind'
import {
  Table,
  TableContainer,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import TableHeader from '@app/components/Table/TableHeader'

export default function TransactionsTable() {
  return (
    <>
      <h2 className={tw`mb-0 text-2xl font-medium leading-9 text-secondary-02`}>
        Transactions
      </h2>

      <TableContainer>
        <Table className="table-auto">
          <TableHeader
            headers={[
              { label: 'Date', className: tw`w-[15%]` },
              { label: 'Description', className: tw`w-[50%]` },
              { label: 'Balance', className: tw`w-[10%]` },
              { label: '', className: tw`w-[10%]` },
            ]}
          />
          <tbody>
            {Array.from({ length: 10 }).map((_, i) => (
              <React.Fragment key={i}>
                <TrWithHoverEffect
                  onClick={() => {}}
                  className={tw`text-gray-04`}
                >
                  <TdWithExtraPadding className={tw`flex items-center`}>
                    <Icon
                      name="file-invoice-dollar"
                      className={tw`mr-4 text-xl text-primary`}
                      variant="solid"
                    />
                    03/{i + 1}/2021
                  </TdWithExtraPadding>

                  <TdWithExtraPadding>Statement Issued</TdWithExtraPadding>
                  <TdWithExtraPadding>$100.00</TdWithExtraPadding>
                  <TdWithExtraPadding>
                    <LinkButton
                      href=""
                      onClick={() => console.log('Remove payment method')}
                      buttonStyle="link"
                      className={tw`h-6 text-sm font-medium lowercase text-gray-07`}
                    >
                      view
                    </LinkButton>
                  </TdWithExtraPadding>
                </TrWithHoverEffect>
                <TrWithHoverEffect
                  onClick={() => {}}
                  className={tw`text-gray-04`}
                >
                  <TdWithExtraPadding className={tw`flex items-center`}>
                    <Icon
                      name="file-invoice-dollar"
                      className={tw`mr-4 text-xl text-primary`}
                      variant="solid"
                    />
                    04/{i + 1}/2021
                  </TdWithExtraPadding>

                  <TdWithExtraPadding>
                    <span className={tw`text-sm font-medium text-primary`}>
                      Payment Received
                    </span>
                  </TdWithExtraPadding>
                  <TdWithExtraPadding>
                    <span className={tw`text-sm font-medium text-primary`}>
                      $300.00
                    </span>
                  </TdWithExtraPadding>
                  <TdWithExtraPadding>
                    <LinkButton
                      href=""
                      onClick={() => console.log('Remove payment method')}
                      buttonStyle="link"
                      className={tw`h-6 text-sm font-medium lowercase text-gray-07`}
                    >
                      view
                    </LinkButton>
                  </TdWithExtraPadding>
                </TrWithHoverEffect>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </>
  )
}
