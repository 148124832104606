import { tw } from '@shared/utils/tailwind'

export default function BalanceCard() {
  return (
    <div
      className={tw`flex h-[130px] min-h-0 w-[560px] animate-fade-in rounded-lg bg-white p-2 shadow-card`}
    >
      <div className={tw`flex w-full flex-col p-4`}>
        <div className={tw`mb-1 flex items-center justify-between`}>
          <span className="font-medium text-gray-07">Current balance</span>
          <span className="font-semibold text-gray-04">$2,576.28</span>
        </div>

        <div className={tw`mb-2 flex items-center justify-between`}>
          <span className="font-medium text-gray-07">
            Last statement balance
          </span>
          <span className="font-semibold text-orange">$500.00</span>
        </div>

        <hr className="border-1 border-gray-10" />

        <div className={tw`mt-2 flex items-center justify-between`}>
          <span className="font-semibold text-gray-04">Total</span>
          <span className="font-semibold text-gray-04">$3,076.28</span>
        </div>
      </div>
      <div
        className={tw`flex w-[164px] flex-col items-center justify-center bg-[#fff4f2] p-6`}
      >
        <span className="font-semibold text-gray-04">DUE NOW</span>
        <span className={tw`mt-2 text-2xl font-semibold text-orange`}>
          $3,076.28
        </span>
      </div>
    </div>
  )
}
