import { OccupancyStats } from '@augusthealth/models/com/august/protos/api/occupancy'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { TaskTemplateInfo } from '@augusthealth/models/com/august/protos/task'
import localForage from 'localforage'
import { useContext, useEffect, useState } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import { fetchFacilityTaskTemplates } from '@shared/api/facility'
import { fetchUser } from '@shared/api/user'
import GatedRoute from '@shared/components/Auth/GatedRoute'
import Login from '@shared/components/Auth/Login'
import Logout from '@shared/components/Auth/Logout'
import { UserSessionSynchronizer } from '@shared/components/Auth/UserSessionSynchronizer'
import ErrorModal from '@shared/components/ErrorModal/ErrorModal'
import Intercom from '@shared/components/Intercom'
import LoadingPopup from '@shared/components/LoadingPopup'
import { hasPermissionForFacility } from '@shared/components/PermissionGates/PermissionGates'
import ScrollToTop from '@shared/components/ScrollToTop'
import Sidebar from '@shared/components/Sidebar/Sidebar'
import CurrentFacilityContext, {
  getConvertToFacilityTimezoneFunc,
} from '@shared/contexts/CurrentFacilityContext'
import GlobalContext from '@shared/contexts/GlobalContext'
import { UserContextProvider } from '@shared/contexts/UserContext'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { ARCHIVED_DOCUMENT_PATHS } from '@shared/hooks/useCurrentPage'
import useLocalStorage from '@shared/hooks/useLocalStorage'
import { SecurityBasedInactivity } from '@shared/hooks/useSecurityBasedInactivity'
import NotFound from '@shared/pages/NotFound'
import Unsubscribe from '@shared/pages/Unsubscribe/Unsubscribe'
import {
  loginPath,
  logoutPath,
  magicEmailPath,
  MAIN_APP_BASE_PATH,
} from '@shared/routes'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { UserAccount } from '@shared/types/user'
import { isConnectivityError } from '@shared/utils/error'
import { getOrElse, LOADING, Loading, mapLoading } from '@shared/utils/loading'
import {
  canEditOwnPermissions,
  hasAdminRole,
  hasRoleWithoutSidebar,
} from '@shared/utils/user'
import AdminMobilePrompt from '@app/components/AdminMobilePrompt'
import CreatePersonModal from '@app/components/CreatePersonModal'
import { IdleOverlay } from '@app/components/IdleOverlay/IdleOverlay'
import Prospects, { PROSPECT_PATHS } from '@app/components/Prospects'
import Residents, { RESIDENT_PATHS } from '@app/components/Residents'
import SidebarContents from '@app/components/SidebarContents'
import { SuppressPermissionsModal } from '@app/components/SuppressPermissionsModal'
import { setNewUserWithUpdatedPermissions } from '@app/components/SuppressPermissionsModal/helpers'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import useFacilitySettings from '@app/hooks/useFacilitySettings'
import usePeopleInFacility from '@app/hooks/usePeopleInFacility'
import { ArchivedDocuments } from '@app/pages/ArchivedDocuments'
import Facilities, { FACILITIES_PATHS } from '@app/pages/Facilities'
import FacilityBilling from '@app/pages/Facilities/Billing'
import { BILLING_PATHS } from '@app/pages/Facilities/Billing/paths'
import Organizations, { ORGANIZATIONS_PATHS } from '@app/pages/Organizations'
import Person, { PERSON_PAGE_PATHS } from '@app/pages/Person'
import Tools from '@app/pages/Tools'
import { TOOLS_PATHS } from '@app/pages/Tools/path'
import RootPathRouter from '@app/routing/RootPathRouter'
import styles from '@app/styles.module.css'

export function PageRouter(props: { defaultRedirectPath: string }) {
  const { error, setError } = useContext(GlobalContext)
  const [facilities, setFacilities] = useState<Facility[] | undefined>()
  const [occupancyStats, setOccupancyStats] = useState<
    OccupancyStats | undefined
  >()
  const [organizations, setOrganizations] = useState<
    Organization[] | undefined
  >()
  const [user, setUser] = useState<UserAccount | undefined>()
  const [showMobilePrompt, setShowMobilePrompt] = useState(false)
  const [currentFacility, setCurrentFacility] = useState<
    Loading<Facility> | undefined
  >()
  const shifts = currentFacility
    ? mapLoading(currentFacility, (facility) => facility.shifts || [])
    : LOADING
  const [configuredTasks, setConfiguredTasks] = useState<
    Loading<TaskTemplateInfo[]> | undefined
  >()
  const [showPermissionsModal, setShowPermissionsModal] = useState(false)
  const [showCreatePersonModal, setShowCreatePersonModal] = useState(false)
  const { defaultRedirectPath } = props

  const [storedSuppressedPermissions] = useLocalStorage<GroupPermission[]>(
    'suppressedPermissions',
    []
  )
  const suppressedPermissions = getOrElse(storedSuppressedPermissions, [])

  useEffect(() => {
    if (user === undefined) {
      fetchUser()
        .then((res) => {
          if (
            suppressedPermissions &&
            suppressedPermissions.length > 0 &&
            canEditOwnPermissions(res)
          ) {
            const newUser = setNewUserWithUpdatedPermissions({
              suppressedPermissions,
              user: res,
            })
            setUser(newUser)
          } else {
            setUser(res)
          }
          setShowMobilePrompt(hasAdminRole(res))
        })
        .catch((err) => {
          if (isConnectivityError(err)) {
            setError(err)
          } else {
            void localForage.clear().then(() => {
              setError(err)
            })
          }
        })
    } else {
      ErrorMonitoring.setUser(user)
    }
  }, [user])

  const peopleInFacility = usePeopleInFacility({
    user,
    currentFacility,
  })
  const facilitySettings = useFacilitySettings({ facility: currentFacility })

  const dependency =
    currentFacility && currentFacility.tag === 'Loading'
      ? undefined
      : currentFacility?.value.id
  useEffect(() => {
    if (currentFacility?.tag === 'Complete') {
      setConfiguredTasks({ tag: 'Loading' })

      const { orgId: oId, id: fId } = currentFacility.value
      void refreshConfiguredTasks(oId!, fId!)
    }
  }, [dependency])

  function handleKeyPress({ ctrlKey, key }) {
    if (ctrlKey && key === '1') {
      setShowPermissionsModal(true)
    }

    if (ctrlKey && key === '2') {
      setShowCreatePersonModal(true)
    }
  }

  useEffect(() => {
    if (canEditOwnPermissions(user)) {
      window.addEventListener('keydown', handleKeyPress)
      return () => {
        window.removeEventListener('keydown', handleKeyPress)
      }
    }

    return undefined
  }, [handleKeyPress])

  const refreshConfiguredTasks = async (orgId: string, facilityId: string) => {
    if (
      hasPermissionForFacility({
        user: user!,
        facility: { orgId, id: facilityId },
        permissions: [GroupPermission.GROUP_PERMISSION_SNAPSHOT_READ],
      })
    ) {
      const response = await fetchFacilityTaskTemplates(orgId, facilityId)
      setConfiguredTasks({
        tag: 'Complete',
        value: response.data,
      })
    } else {
      setConfiguredTasks({
        tag: 'Complete',
        value: [],
      })
    }
  }

  if (!user) {
    if (error instanceof TypeError || error?.status === 503) {
      // 503 error can be triggered without user
      return <ErrorModal user={user} />
    } else {
      return <LoadingPopup loading />
    }
  }

  const facilityTimeZone =
    currentFacility?.tag === 'Complete'
      ? currentFacility.value.timeZone
      : undefined

  return (
    <UserContextProvider value={{ user, setUser }}>
      <UserSessionSynchronizer>
        <FacilitiesContext.Provider
          value={{
            facilities,
            setFacilities,
            occupancyStats,
            setOccupancyStats,
            organizations,
            setOrganizations,
          }}
        >
          <BrowserRouter>
            <CompatRouter>
              <SecurityBasedInactivity />
              <CurrentFacilityContext.Provider
                value={{
                  configuredTasks,
                  setConfiguredTasks,
                  currentFacility,
                  shifts,
                  setCurrentFacility: (facility: Facility | undefined) => {
                    if (facility) {
                      setCurrentFacility({ tag: 'Complete', value: facility })
                    } else {
                      setCurrentFacility(facility)
                    }
                  },
                  ...peopleInFacility,
                  settings: facilitySettings,
                  convertToFacilityTimezone:
                    getConvertToFacilityTimezoneFunc(facilityTimeZone),
                }}
              >
                <IdleOverlay
                  timeout={
                    process.env.REACT_APP_ENV === 'local' ? 1.8e6 : 300000
                  }
                />
                {showPermissionsModal && (
                  <SuppressPermissionsModal
                    onClose={(newUser: UserAccount | undefined) => {
                      if (newUser) {
                        setUser(newUser)
                        window.location.reload()
                      }
                      setShowPermissionsModal(false)
                    }}
                    user={user}
                  />
                )}
                {showCreatePersonModal && (
                  <CreatePersonModal
                    user={user}
                    onClose={() => setShowCreatePersonModal(false)}
                  />
                )}
                <Switch key="on-page-change-switch">
                  <Route path="*" component={ScrollToTop} />
                </Switch>
                <Switch key="error-popup">
                  <Route path="*">
                    <ErrorModal user={user} />
                  </Route>
                </Switch>
                <Switch key="sidebar-decider">
                  <Route
                    path={'/unsubscribe'}
                    component={() => {
                      return (
                        <div
                          className={
                            'flex h-screen items-center justify-center'
                          }
                        >
                          <Unsubscribe />
                        </div>
                      )
                    }}
                  />
                  <Route
                    path={logoutPath(MAIN_APP_BASE_PATH)}
                    exact
                    component={() => <Logout />}
                  />
                  <Route
                    exact
                    path={magicEmailPath(MAIN_APP_BASE_PATH)}
                    component={() => (
                      <Login applicationBasePath={MAIN_APP_BASE_PATH} />
                    )}
                  />
                  <Route path="/" exact>
                    <RootPathRouter user={user} />
                  </Route>
                  <GatedRoute
                    exact
                    path={ARCHIVED_DOCUMENT_PATHS}
                    minimalPermissions={[
                      GroupPermission.GROUP_PERMISSION_PERSON_READ,
                      GroupPermission.GROUP_PERMISSION_SNAPSHOT_READ,
                    ]}
                    component={ArchivedDocuments}
                  />
                  <GatedRoute
                    exact
                    path={TOOLS_PATHS}
                    minimalPermissions={[
                      GroupPermission.GROUP_PERMISSION_TOOL_GENERAL,
                    ]}
                    component={Tools}
                    title={'Tools'}
                  />
                  <Route path="*">
                    <div className={styles.contentContainer}>
                      <Intercom user={user} currentFacility={currentFacility} />
                      <Route
                        render={({ location }) => {
                          if (hasRoleWithoutSidebar(user)) {
                            return null
                          }

                          return location.pathname.endsWith('/orgs') ||
                            location.pathname.endsWith('/orgs/') ? (
                            <></>
                          ) : (
                            <Sidebar>
                              <SidebarContents
                                setShowPermissionsModal={
                                  setShowPermissionsModal
                                }
                              />
                            </Sidebar>
                          )
                        }}
                      />
                      <section className={styles.content}>
                        <Switch key="content-switch">
                          <Redirect
                            from={magicEmailPath(MAIN_APP_BASE_PATH)}
                            to={defaultRedirectPath}
                          />
                          <Redirect
                            from={loginPath(MAIN_APP_BASE_PATH)}
                            to={defaultRedirectPath}
                          />
                          <Route
                            exact
                            path={ORGANIZATIONS_PATHS}
                            component={Organizations}
                          />
                          <Route
                            exact
                            path={FACILITIES_PATHS}
                            component={Facilities}
                          />
                          <Route
                            exact
                            path={PERSON_PAGE_PATHS}
                            component={Person}
                          />
                          <Route
                            exact
                            path={PROSPECT_PATHS}
                            component={Prospects}
                          />
                          <Route
                            exact
                            path={RESIDENT_PATHS}
                            component={Residents}
                          />
                          <Route
                            exact
                            path={BILLING_PATHS}
                            component={FacilityBilling}
                          />
                          <Route
                            exact
                            path={ARCHIVED_DOCUMENT_PATHS}
                            component={undefined}
                          />
                          <Route component={NotFound} />
                        </Switch>
                      </section>
                    </div>
                    {showMobilePrompt && (
                      <div className={styles.mobilePromptContainer}>
                        <AdminMobilePrompt
                          onClose={() => {
                            setShowMobilePrompt(false)
                          }}
                        />
                      </div>
                    )}
                  </Route>
                </Switch>
              </CurrentFacilityContext.Provider>
            </CompatRouter>
          </BrowserRouter>
        </FacilitiesContext.Provider>
      </UserSessionSynchronizer>
    </UserContextProvider>
  )
}
