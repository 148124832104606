import { ControlProps, GroupBase, StylesConfig } from 'react-select'

export const baseSelectControlStyles = (
  size = 'medium',
  state: ControlProps<unknown, boolean, GroupBase<unknown>>
) => ({
  borderColor:
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    !state.isDisabled && state.isFocused
      ? 'var(--form-input-border-hover) !important'
      : 'var(--form-input-border)',
  height:
    size === 'large'
      ? 'var(--form-input-large-height)'
      : 'var(--form-input-height)',
  cursor: 'cursor-pointer',
  fontSize: 'var(--form-input-font-size)',
  fontWeight: '500',
  borderRadius: 'var(--form-input-border-radius)',
  lineHeight: '16px',
  boxShadow: 'none',
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  outline: state.isFocused
    ? 'double 1px var(--form-input-border-hover) !important'
    : 'double 1px transparent !important',
  '&:hover': {
    borderColor: 'var(--form-input-border-hover) !important',
  },
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  backgroundColor: state.isDisabled
    ? 'var(--form-input-disabled-background-color)'
    : 'white',
})

export const errorBorderControlStyles = (
  error,
  state: ControlProps<unknown, boolean, GroupBase<unknown>>
) => ({
  borderColor: `var(${
    error ? '--form-input-error-border' : '--form-input-border'
  })`,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  outline: state.isFocused
    ? `double 1px var(${
        error ? '--form-input-error-border' : '--form-input-border-hover'
      }) !important`
    : 'double 1px transparent !important',
  '&:hover': {
    borderColor: `var(${
      error ? '--form-input-error-border' : '--form-input-border-hover'
    }) !important`,
  },
})

export const selectStyles = (size = 'medium'): StylesConfig => ({
  control: (provided, state) => ({
    ...provided,
    ...baseSelectControlStyles(size, state),
  }),
  option: (provided) => ({
    ...provided,
    cursor: 'cursor-pointer',
    fontSize: '14px',
  }),
  indicatorSeparator: () => ({}),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: '9999',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#595451',
    '&:hover': {
      color: '#595451',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled
      ? 'var(--form-input-disabled-text-color)'
      : 'var(--gray-04)',
  }),
})

export const modifiedStyles = (
  size = 'medium'
): StylesConfig<unknown, boolean, GroupBase<unknown>> => ({
  ...selectStyles(size),
  menu: (provided) => ({
    ...provided,
    zIndex: '9999',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0px 4px 0px 0px',
  }),
})

export const modifiedWithIconStyles = (
  size = 'medium'
): StylesConfig<unknown, boolean, GroupBase<unknown>> => ({
  ...modifiedStyles(size),
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    ...baseSelectControlStyles(size, state),
    width: '100%',
    paddingLeft: 'var(--form-input-icon-left-padding)',
  }),
  valueContainer: (provided) => ({
    ...provided,
    width: '100%',
    paddingLeft: '0px',
  }),
})

export const inlineFormStyles: StylesConfig<
  unknown,
  boolean,
  GroupBase<unknown>
> = {
  ...selectStyles('medium'),
}

export const ErrorDropdownStyleConfig = ({
  error,
  width,
}: {
  error: boolean
  width?: string
}): StylesConfig<unknown, boolean, GroupBase<unknown>> => {
  const widthStyle = width ?? 'fit-content'
  return {
    ...selectStyles(),
    control: (provided, state) => ({
      ...provided,
      ...baseSelectControlStyles('medium', state),
      ...errorBorderControlStyles(error, state),
      minWidth: '96px',
      width: widthStyle,
    }),
    menu: (provided) => ({
      ...provided,
      width: widthStyle,
    }),
  }
}

export const BadgeSelectStyleConfig = ({
  inputName,
  error,
}: {
  inputName: string
  error?: boolean
}): StylesConfig<unknown, boolean, GroupBase<unknown>> => {
  const labelElement = document.getElementById(`label-${inputName}`)
  const labelElementWidth = labelElement
    ? labelElement.getBoundingClientRect().width + 8
    : 20
  return {
    ...selectStyles(),
    container: (provided) => ({
      ...provided,
      overscrollBehavior: 'contain',
    }),
    control: (provided, state) => ({
      ...provided,
      ...baseSelectControlStyles('medium', state),
      ...errorBorderControlStyles(error, state),
      borderRadius: '0',
      minWidth: '96px',
      width: '100%',
      height: '100%',
      borderWidth: '0px',
      boxShadow: 'none',
      outline: 'none',
      '&:hover': {
        borderWidth: '0px',
        borderColor: 'transparent',
        boxShadow: 'none',
      },
    }),
    menu: (provided) => ({
      ...provided,
      overscrollBehavior: 'contain',
      width: `calc(100% + ${labelElementWidth}px)`,
      right: '0',
    }),
  }
}

export const multiSelectStyles: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    ...baseSelectControlStyles('default', state),
    height: 'fit-content',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    textOverflow: 'string !important',
  }),
}
