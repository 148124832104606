import { VictoryContainer, VictoryLegend } from 'victory'
import Card from '@shared/components/Card'
import { CHART_COLORS } from '@shared/constants/victory'
import { RoutineAdministration, ShiftOccurrence } from '@shared/types/careapp'
import { tw } from '@shared/utils/tailwind'
import { BASE_FONT } from '@app/libraries/Victory/theme'
import { getRoutineAdminByShiftOccurrence } from './helpers'
import TableChart from './TableChart'

const { LABEL: labelColors } = CHART_COLORS

export default function RoutineCharts({
  allRoutineAdministrations,
  selectedShiftOccurrence,
  shiftOccurrences,
  setShiftId,
  facilityTimeZone,
}: {
  allRoutineAdministrations: RoutineAdministration[]
  selectedShiftOccurrence: ShiftOccurrence
  shiftOccurrences: ShiftOccurrence[]
  setShiftId: (shiftId: string) => void
  facilityTimeZone: string
}) {
  const list = getRoutineAdminByShiftOccurrence({
    routineAdministrations: allRoutineAdministrations,
    shiftOccurrences,
  })

  return (
    <Card className={tw`mb-[24px] w-full flex-col`}>
      <VictoryLegend
        itemsPerRow={4}
        orientation="horizontal"
        gutter={16}
        height={20}
        style={{ labels: { fontSize: 12, fontFamily: BASE_FONT } }}
        data={[
          { name: 'Not Started yet', symbol: { fill: labelColors.MUTED } },
          { name: 'Exceptions', symbol: { fill: labelColors.WARNING } },
          { name: 'Overdue', symbol: { fill: labelColors.DANGER } },
          { name: 'Done', symbol: { fill: labelColors.SUCCESS } },
        ]}
        containerComponent={<VictoryContainer responsive={false} />}
      />
      <div className={tw`flex gap-[24px]`}>
        {list.map((item) => {
          const { shift, routineAdministrations } = item

          return (
            <TableChart
              key={`table-chart-${shift.id}`}
              shift={shift}
              routineAdministrations={routineAdministrations}
              selectedShiftId={selectedShiftOccurrence?.shift.id}
              setShiftId={setShiftId}
              facilityTimeZone={facilityTimeZone}
            />
          )
        })}
      </div>
    </Card>
  )
}
