import {
  CareAppPerson,
  Instant,
  RoutineAdministration,
  RoutineAdministrationStatus,
} from '@shared/types/careapp'
import { isAfterInZone, isBeforeInZone } from './localDateTime'

export function routineAdminIsDone(
  routineAdministration: RoutineAdministration
) {
  return (
    routineAdministration.status ===
    RoutineAdministrationStatus.ROUTINE_ADMINISTRATION_STATUS_ADMINISTERED
  )
}

export function routineAdminIsException(
  routineAdministration: RoutineAdministration
) {
  return (
    routineAdministration.status ===
    RoutineAdministrationStatus.ROUTINE_ADMINISTRATION_STATUS_EXCEPTION
  )
}

export function routineAdminIsOverDue(
  routineAdministration: RoutineAdministration,
  facilityTimeZone: string
) {
  const { endAdministrationWindow, status } = routineAdministration
  const now = Instant(new Date())

  return (
    status ===
      RoutineAdministrationStatus.ROUTINE_ADMINISTRATION_STATUS_UNSPECIFIED &&
    isAfterInZone(now, endAdministrationWindow, facilityTimeZone)
  )
}

export function routineAdminIsNotStarted(
  routineAdministration: RoutineAdministration,
  timeZone: string
) {
  const { endAdministrationWindow, status } = routineAdministration
  const now = Instant(new Date())

  return (
    status ===
      RoutineAdministrationStatus.ROUTINE_ADMINISTRATION_STATUS_UNSPECIFIED &&
    isBeforeInZone(now, endAdministrationWindow, timeZone)
  )
}

export function getAdministrationsForPerson({
  person,
  routineAdministrations,
}: {
  person: Pick<CareAppPerson, 'id'>
  routineAdministrations: RoutineAdministration[]
}) {
  return routineAdministrations.filter(
    (administration) => administration.personId === person.id
  )
}
