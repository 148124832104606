import { isBefore, parseISO, startOfToday } from 'date-fns'
import {
  BillingEvent,
  BillingEventIdType,
  BillingEventType,
  RecurringCharge,
  ResidentListEntry,
  Statement,
  StatementStatus,
} from '@shared/types/billing'
import { Contact } from '@shared/types/contact'

export type BillingModalType =
  | 'ADD_CHARGE'
  | 'ADD_CREDIT'
  | 'ADD_INVOICE'
  | 'DELETE_CHARGE_OR_CREDIT'
  | 'EDIT_CHARGE'
  | 'END_CHARGE'
  | 'SEND_STATEMENT'
  | { tag: 'ADD_PAYMENT_METHOD_MODAL'; contact: Contact }

/**
 * TO DO: Similar code in function centsToMoney (src/shared/utils/charge.ts)
 * Put negative in accounting format (inside parentheses without sign)
 */
export function formatCurrencyForBilling(amountCents: number) {
  return (amountCents / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    currencySign: 'accounting',
  })
}

export function isPendingOneTimeCharge(transaction: BillingEvent) {
  const { idType, transactionType, pending } = transaction

  return (
    idType === BillingEventIdType.INVOICE_ITEM &&
    (transactionType === BillingEventType.ONE_TIME ||
      transactionType === BillingEventType.CREDIT) &&
    pending
  )
}

export function isPendingRecurringCharge(transaction: BillingEvent) {
  const { idType, transactionType, pending } = transaction

  return (
    idType === BillingEventIdType.BILLING_CHARGE &&
    transactionType === BillingEventType.RECURRING &&
    pending
  )
}

export function transactionCanBeDeleted(transaction: BillingEvent) {
  return (
    isPendingOneTimeCharge(transaction) || isPendingRecurringCharge(transaction)
  )
}

export function transactionCanBeUpdated(transaction: BillingEvent) {
  return (
    (isPendingOneTimeCharge(transaction) ||
      isPendingRecurringCharge(transaction)) &&
    !transaction.serviceEndDate
  )
}

/**
 * Only use for DeleteConfirmModal which take a transaction instead of charge
 */
export function convertChargeToFakeTransaction(
  charge: RecurringCharge
): BillingEvent {
  const {
    meta: { id, createdAt },
    data: { amountCents, name, startDate, endDate },
  } = charge

  return {
    id,
    amountCents,
    description: name,
    idType: BillingEventIdType.BILLING_CHARGE,
    pending: true,
    createdAt,
    serviceStartDate: startDate,
    serviceEndDate: endDate || startDate,
    transactionType: BillingEventType.RECURRING,
    balanceCents: amountCents,
  }
}

/**
    Can draft a statement when the resident has no invoice or last invoice is due or paid
*/
export function canDraftStatement(resident: ResidentListEntry) {
  const { lastInvoice } = resident

  return !lastInvoice || isSuccessfullyIssued(lastInvoice)
}

export function isSuccessfullyIssued(statement: Statement) {
  return [StatementStatus.DUE, StatementStatus.PAID].includes(
    statement.data.status
  )
}

/**
 * A resident should only have 1 or 0 pending statements at a time
 */
export function getPendingInvoiceTransaction(transactions: BillingEvent[]) {
  const filtered = transactions.filter((t) => {
    return (
      t.pending &&
      t.idType === BillingEventIdType.INVOICE &&
      t.transactionType === BillingEventType.STATEMENT
    )
  })
  if (filtered.length) {
    return filtered[0]
  } else {
    return null
  }
}

export function formatDateToMonthYearLabel(d: Date) {
  return d.toLocaleDateString('default', {
    year: 'numeric',
    month: 'long',
  })
}

export function formatDateTo10DigitsIsoStr(d: Date) {
  return d.toISOString().substring(0, 10)
}

export function billingChargeIsEnded(billingCharge: RecurringCharge) {
  const { endDate } = billingCharge.data
  return endDate && isBefore(parseISO(endDate), startOfToday())
}

/**
 * Currently display all previous months, current month and next month
 */

export function getBillingPeriodOptions(options?: {
  minDate?: Date
  returnIsoStr?: boolean
}) {
  const { minDate, returnIsoStr } = options || {}
  const today = new Date()
  const currentYear = today.getFullYear()

  return new Array(today.getMonth() + 2)
    .fill(undefined)
    .map((_, monthIndex) => {
      const d = new Date(currentYear, monthIndex, 1)

      return {
        label: formatDateToMonthYearLabel(d),
        value: returnIsoStr ? formatDateTo10DigitsIsoStr(d) : monthIndex,
        isDisabled: minDate && d <= minDate,
      }
    })
}
