import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import ProgressRoutinesPage from './Routines'

export default function Progress() {
  const { currentFacility } = useCurrentFacility()
  if (currentFacility?.tag === 'Complete') {
    // Currently default Progress Routines page
    // Plan to add Progress Medications page in future
    return <ProgressRoutinesPage facility={currentFacility.value} />
  }

  return <SimpleSpinner />
}
