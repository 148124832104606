import { CSSProperties } from 'react'
import { CHART_COLORS } from '@shared/constants/victory'
import {
  RoutineAdministration,
  RoutineAdministrationProgressType,
  ShiftOccurrence,
} from '@shared/types/careapp'
import {
  routineAdminIsDone,
  routineAdminIsException,
  routineAdminIsNotStarted,
  routineAdminIsOverDue,
} from '@shared/utils/careapp'
import { BarChartData } from '@app/libraries/Victory/helpers'

type CountersData = Record<RoutineAdministrationProgressType, number>

type RoutineBarChartData = Required<
  BarChartData<{
    routineCount: number
    routineType: RoutineAdministrationProgressType
  }>
>

function generateBarChartData(
  counterData: CountersData
): RoutineBarChartData[] {
  let previousY = 0

  return Object.entries(counterData).map(([k, v]) => {
    const nextY = previousY + Math.sqrt(v) * 10 // Make small bar more appearing
    const data = {
      x: 1,
      y0: previousY,
      y: nextY,
      data: {
        routineCount: v,
        routineType: k as RoutineAdministrationProgressType,
      },
    }

    previousY = nextY

    return data
  })
}

const { LABEL: labelColors, BAR_FILL: barFillColors } = CHART_COLORS

function getBarFillColor(type: RoutineAdministrationProgressType) {
  switch (type) {
    case 'Done':
      return barFillColors.SUCCESS
    case 'Exceptions':
      return barFillColors.WARNING
    case 'Overdue':
      return barFillColors.DANGER
    default:
      return barFillColors.MUTED
  }
}

function getBarLabel(type: RoutineAdministrationProgressType) {
  switch (type) {
    case 'Done':
      return labelColors.SUCCESS
    case 'Exceptions':
      return labelColors.WARNING
    case 'Overdue':
      return labelColors.DANGER
    default:
      return 'black'
  }
}

function generateCountersData(
  routineAdministrations: RoutineAdministration[],
  facilityTimeZone: string
): Record<RoutineAdministrationProgressType, number> {
  return routineAdministrations.reduce(
    (hash, routine) => {
      if (routineAdminIsDone(routine)) {
        hash.Done = hash.Done + 1
      } else if (routineAdminIsException(routine)) {
        hash.Exceptions = hash.Exceptions + 1
      } else if (routineAdminIsOverDue(routine, facilityTimeZone)) {
        hash.Overdue = hash.Overdue + 1
      } else if (routineAdminIsNotStarted(routine, facilityTimeZone)) {
        hash['Not started yet'] = hash['Not started yet'] + 1
      }

      return hash
    },
    {
      'Not started yet': 0,
      Exceptions: 0,
      Overdue: 0,
      Done: 0,
    }
  )
}

type TableChartBar = {
  count: number
  key: string
  style: CSSProperties
}

export function getBarStyles({
  routineType,
  width,
}: {
  routineType: RoutineAdministrationProgressType
  width: string
}) {
  return {
    backgroundColor: getBarFillColor(routineType),
    color: getBarLabel(routineType),
    width,
  }
}

export function generateTableChartData(
  routineAdministrations: RoutineAdministration[],
  facilityTimeZone: string
): TableChartBar[] {
  const countersData = generateCountersData(
    routineAdministrations,
    facilityTimeZone
  )
  const barChartData = generateBarChartData(countersData)

  return barChartData.map((data) => {
    const lastY = barChartData.at(-1)!.y
    const {
      y0,
      y,
      data: { routineCount, routineType },
    } = data
    const diff = y - y0

    return {
      style: getBarStyles({
        routineType,
        width: `${Math.floor((diff / lastY) * 100)}%`,
      }),
      count: routineCount,
      key: routineType,
    }
  })
}

export function getRoutineAdminByShiftOccurrence({
  routineAdministrations,
  shiftOccurrences,
}: {
  routineAdministrations: RoutineAdministration[]
  shiftOccurrences: ShiftOccurrence[]
}) {
  return shiftOccurrences.map(({ shift }) => {
    return {
      shift,
      routineAdministrations: routineAdministrations.filter(
        (r) => r.shiftId === shift.id
      ),
    }
  })
}
