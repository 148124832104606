/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { getETag } from '@shared/api/etags'
import { getUrl } from '@shared/api/legacy'
import { fetchJson } from '@shared/api/request'
import { fetchUser } from '@shared/api/user'
import environment from '@shared/environment'
import { Person, RequiredPersonIds } from '@shared/types/person'
import { Signer } from '@shared/types/snapshot'
import { SignatureStatus, Task } from '@shared/types/task'
import { isResponsiblePerson } from '@shared/utils/user'
import {
  possibleSignersUrl,
  processSignatureUrl,
  signaturePacketUrl,
} from './urls'

const BASE_URL = environment.baseUrl
const ORGANIZATIONS_URL = `${BASE_URL}/organizations`
const ORG_URL = `${ORGANIZATIONS_URL}/:orgId`
const PERSON_URL = `${ORG_URL}/people/:personId`
const TASKS_URL = `${PERSON_URL}/tasks`
const TASK_URL = `${TASKS_URL}/:taskId`

export function fetchTasks({
  pId,
  orgId,
}: {
  pId: string
  orgId: string
}): Promise<{
  data: Task[]
}> {
  const url = getUrl({ pId, orgId, baseUrl: TASKS_URL })
  return fetchJson(url) as Promise<{ data: Task[] }>
}

export function fetchTask({
  pId,
  orgId,
  taskId,
}: {
  pId: string
  orgId: string
  taskId: string
}) {
  const url = getUrl({ pId, orgId, taskId, baseUrl: TASK_URL })
  return fetchJson(url) as Promise<{ data: Task }>
}

/**
 * Issue this API request when the admin clicks a task that is in state COMPLETE_BUT_NEW.
 *
 * There's no need to wait until this completes, it's just to let the server know to update the task status.
 */
export async function onViewCompletedTask({
  pId,
  facilityId,
  orgId,
  taskId,
}: {
  pId: string
  facilityId: string
  orgId: string
  taskId: string
}) {
  const user = await fetchUser()

  if (!isResponsiblePerson({ user, orgId, facilityId, personId: pId })) {
    const task: Record<string, any> = (await fetchTask({
      pId,
      orgId,
      taskId,
    })) as Record<string, any>
    if (task.data.status === 'TASK_STATUS_COMPLETE_BUT_NEW') {
      return mergePatchTask({
        pId,
        orgId,
        taskId,
        task: { status: 'TASK_STATUS_COMPLETE' },
      })
    }
  }

  return {}
}

export function mergePatchTask({
  pId,
  orgId,
  taskId,
  task = {},
  withEtagProtection = false,
}) {
  const url = getUrl({ pId, orgId, taskId, baseUrl: TASK_URL })
  const headers = withEtagProtection ? { 'If-Match': getETag(url) || '*' } : {}

  return fetchJson(url, {
    method: 'PATCH',
    body: JSON.stringify(task),
    headers: {
      'Content-Type': 'application/merge-patch+json',
      ...headers,
    },
  })
}

/**
 * Signifies that the task was updated, or the data behind it was edited, by the given
 * user at this point in time.
 */
export function touchTask({ pId, orgId, taskId }) {
  return mergePatchTask({ pId, orgId, taskId })
}

export function listPossibleSigners({
  personIds,
  taskId,
  includesRp,
}: {
  personIds: RequiredPersonIds
  taskId: string
  includesRp?: boolean
}) {
  const { orgId, facilityId, id: personId } = personIds
  const url = possibleSignersUrl({
    orgId,
    facilityId,
    personId,
    taskId,
    includesRp,
  })

  return fetchJson(url)
}

type SignerPlusSkip = Signer & {
  skip?: boolean
}
interface SignaturePacketProps {
  person: Person
  task: Task
  signers: SignerPlusSkip[]
}

export type SignatureInfoWithSkip = {
  signer?: SignerPlusSkip
  status?: SignatureStatus
  /** Signer description */
  description?: string
}

export function updateSignaturePacket({
  person,
  task,
  signers,
}: SignaturePacketProps) {
  const url = signaturePacketUrl({
    orgId: person.orgId,
    facilityId: person.facilityId,
    personId: person.id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    taskId: task.id!,
  })

  return fetchJson(url, {
    method: 'PUT',
    body: JSON.stringify(signers),
  })
}

export function createSignaturePacket({
  person,
  task,
  signers,
}: SignaturePacketProps) {
  const url = signaturePacketUrl({
    orgId: person.orgId,
    facilityId: person.facilityId,
    personId: person.id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    taskId: task.id!,
  })

  return fetchJson(url, {
    method: 'POST',
    body: JSON.stringify(signers),
  })
}

export function processSignature({
  orgId,
  facilityId,
  personId,
  taskId,
  email,
}: {
  orgId: string
  facilityId: string
  personId: string
  taskId: string
  email: string
}) {
  const url = processSignatureUrl({
    orgId,
    facilityId,
    personId,
    taskId,
    email,
  })

  return fetchJson(url, {
    method: 'POST',
    body: JSON.stringify({ email }),
  })
}
