import { CareAppPerson, RoutineAdministration } from '@shared/types/careapp'
import { mergeCareAppPeopleWithRoutineAdmins } from './helpers'
import ResidentRoutinesCard from './ResidentRoutinesCard'

export default function RoutineResidentCardList({
  careAppPeople,
  routineAdministrations,
  facilityTimeZone,
}: {
  careAppPeople: CareAppPerson[]
  routineAdministrations: RoutineAdministration[]
  facilityTimeZone: string
}) {
  return (
    <>
      {mergeCareAppPeopleWithRoutineAdmins({
        careAppPeople,
        routineAdministrations,
      }).map((data) => {
        const { person, administrations } = data

        return (
          <ResidentRoutinesCard
            key={`person-card-${person.id}`}
            careAppPerson={person}
            routineAdministrations={administrations}
            facilityTimeZone={facilityTimeZone}
          />
        )
      })}
    </>
  )
}
