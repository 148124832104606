import { sortBy } from 'lodash'
import { CareAppPerson, RoutineAdministration } from '@shared/types/careapp'
import { getAdministrationsForPerson } from '@shared/utils/careapp'
import { getFullName } from '@shared/utils/humanName'

export function mergeCareAppPeopleWithRoutineAdmins({
  careAppPeople,
  routineAdministrations,
}: {
  careAppPeople: CareAppPerson[]
  routineAdministrations: RoutineAdministration[]
}) {
  const peopleWithRoutines = careAppPeople.map((person) => {
    return {
      person,
      administrations: getAdministrationsForPerson({
        person,
        routineAdministrations,
      }),
    }
  })

  return sortBy(peopleWithRoutines, [
    (p) => !p.administrations.length, // People with administrations at the top
    (p) => getFullName(p.person.name),
  ])
}
