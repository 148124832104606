import { subDays } from 'date-fns'
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import { Array, Option as EffectOption, pipe } from 'effect'
import { none, some } from 'effect/Option'
import {
  IsoDate,
  ShiftOccurrence,
  ShiftOccurrenceRef,
} from '@shared/types/careapp'
import { Time } from '@shared/types/date'
import { Shift } from '@shared/types/shift'
import { isBefore, isOnOrAfter } from '@shared/utils/time'

export function toShiftOccurrenceRef(
  shiftOccurrence: ShiftOccurrence
): ShiftOccurrenceRef {
  return `${shiftOccurrence.date}|${shiftOccurrence.shift.id}` as ShiftOccurrenceRef
}

export function optionFromShiftOccurrenceRef(
  shifts: Shift[],
  shiftOccurrenceRef: ShiftOccurrenceRef
): EffectOption.Option<ShiftOccurrence> {
  const [date, shiftId] = shiftOccurrenceRef.split('|')

  return pipe(
    shifts,
    Array.findFirst(({ id }) => id === shiftId),
    EffectOption.map((shift) => ({ date: date as IsoDate, shift }))
  )
}

function isOvernight(period: Shift['period']): boolean {
  return (period.startTime.hour || 0) > (period.endTime.hour || 0)
}

function isWithinOvernightPeriod(
  time: Time,
  overnightPeriod: Shift['period']
): boolean {
  return (
    isOnOrAfter(time, overnightPeriod.startTime) ||
    isBefore(time, overnightPeriod.endTime)
  )
}

/**
 * @returns a boolean indicating whether or not the given shift period is
 * within the given time, respecting overnight shift logic
 */
function isWithinPeriod(time: Time, period: Shift['period']): boolean {
  return (
    (isOvernight(period) && isWithinOvernightPeriod(time, period)) ||
    (isOnOrAfter(time, period.startTime) && isBefore(time, period.endTime))
  )
}

function toZonedTime(date: Date, timeZone: string): Time {
  const zonedDate = utcToZonedTime(date, timeZone)
  return {
    hour: zonedDate.getHours(),
    minute: zonedDate.getMinutes(),
  }
}

function isoDate({
  period,
  date,
  timeZone,
}: {
  period: Shift['period']
  date: Date
  timeZone: string
}): IsoDate {
  if (
    isOvernight(period) &&
    isWithinOvernightPeriod(toZonedTime(date, timeZone), period)
  ) {
    return formatInTimeZone(subDays(date, 1), timeZone, 'yyyy-MM-dd') as IsoDate
  } else {
    return formatInTimeZone(date, timeZone, 'yyyy-MM-dd') as IsoDate
  }
}

/**
 * Given a list of shifts and a date, return the correct shift occurrence that
 * date (and time) corresponds to.
 *
 * There will always be a shift that spans overnight (midnight),
 * and when the input date is in within the period of that overnight shift,
 * return a shift occurrence that includes the date that started before midnight.
 *
 * For instance: If we are 2am on Monday, and there exists an overnight shift
 * with a beginning period of 10pm and an ending period of 6am,
 * we want a shift occurence that roughly translates to:
 *
 * "Sunday:NOC"
 */
export function shiftOccurrence({
  shifts,
  date,
  timeZone,
}: {
  shifts: Shift[]
  date: Date
  timeZone: string
}): EffectOption.Option<ShiftOccurrence> {
  const shiftForDate = shifts.find((shift) =>
    isWithinPeriod(toZonedTime(date, timeZone), shift.period)
  )

  if (!shiftForDate) {
    return none()
  } else {
    return some({
      shift: shiftForDate,
      date: isoDate({
        period: shiftForDate.period,
        date,
        timeZone,
      }),
    })
  }
}

/**
 * Generate shift occurrences relative to the input date,
 * pay special attention to overnight shifts which could have a shift
 * occurrence of the date before forDate depending on whether or not the
 * date falls within the shift period.
 */
export function shiftOccurrences({
  shifts,
  date,
  timeZone,
}: {
  shifts: Shift[]
  date: Date
  timeZone: string
}): ShiftOccurrence[] {
  return shifts.map((shift) => ({
    shift,
    date: isoDate({
      period: shift.period,
      date,
      timeZone,
    }),
  }))
}
