import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { match } from 'ts-pattern'
import { login as requestLoginWithMagicLink } from '@shared/api/login'
import { login } from '@shared/components/Auth/helpers'
import LogoHeader from '@shared/components/LogoHeader'
import GlobalContext from '@shared/contexts/GlobalContext'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { RequiredUserActionType } from '@shared/types/auth'
import { buildAugustError, isConnectivityError } from '@shared/utils/error'
import { tw } from '@shared/utils/tailwind'
import EmailLoginForm from './EmailLoginForm'
import Footer from './Footer'
import ResetPassword from './ForgotPassword/ResetPassword'
import Loader from './Loader'
import UsernameLoginForm from './UsernameLoginForm'

type LoginStep = 'username' | 'email' | 'forgotPassword' | undefined
export type BtnState = 'engaged' | 'disengaged' | 'default'

export const DEFAULT_LOGIN_TITLE = ''
export default function LoginWithUsernameOrEmail({
  title = DEFAULT_LOGIN_TITLE,
  applicationBasePath,
}: {
  title?: string
  applicationBasePath: string
}) {
  const history = useHistory()
  const [loginStep, setLoginStep] = useState<LoginStep>('username')
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [userToReset, setUserToReset] = useState<string | undefined>(undefined)
  const [passwordToReset, setPasswordToReset] = useState<string | undefined>(
    undefined
  )
  const { setError } = useContext(GlobalContext)

  if (isLoading) {
    return <Loader title="Logging you in" />
  }

  function getBtnState(type: LoginStep) {
    if (loginStep === undefined) {
      return 'default'
    }

    if (loginStep === type) {
      return 'engaged'
    }

    return 'disengaged'
  }

  async function onEmailSubmit({ email }: { email: string }) {
    if (loginStep !== 'email') {
      return setLoginStep('email')
    }

    setSubmitted(true)
    setEmail(email)
    await requestLoginWithMagicLink(email, history.location.pathname)
  }

  async function onUsernameSubmit({
    preferredUsername,
    password,
  }: {
    preferredUsername: string
    password: string
  }) {
    // Also allow to login right after reset password
    const isReseting = userToReset && passwordToReset
    if (loginStep !== 'username' && !isReseting) {
      return setLoginStep('username')
    }

    setIsLoading(true)
    try {
      const { action, userAlias } = await login(preferredUsername, password)
      match(action)
        .with(RequiredUserActionType.NO_ACTION, () => {
          setUserToReset(undefined)
          setPasswordToReset(undefined)
          window.location.pathname = history.location.pathname
        })
        .with(RequiredUserActionType.NEW_PASSWORD_REQUIRED, () => {
          setUserToReset(userAlias || preferredUsername)
          setPasswordToReset(password)
          setIsLoading(false)
        })
        .with(RequiredUserActionType.ACCOUNT_LOCKED_TRY_AGAIN_LATER, () => {
          setError(
            buildAugustError({
              name: 'Account Locked',
              message:
                "You've attempted to login too many times. Try again later",
            })
          )
          setIsLoading(false)
        })
        .otherwise((action) => {
          ErrorMonitoring.capture({
            error: 'Unrecognized required action',
            level: 'warning',
            extras: {
              action: action as string,
            },
          })
          setIsLoading(false)
          setError(
            buildAugustError({
              name: 'Unrecognized required action',
              message:
                'Unable to login. Please reach out to August Health support for assistance',
            })
          )
        })
    } catch (e) {
      const error = isConnectivityError(e)
        ? e
        : buildAugustError({
            name: 'Something went wrong!',
            message:
              'Unable to login. Please reach out to August Health support for assistance',
          })

      setError(error)
      setIsLoading(false)
    }
  }

  if (submitted) {
    return (
      <div className={tw`flex flex-col items-center`} slot="sign-in">
        <LogoHeader title={`We've sent a temporary login link to ${email}`} />
        <Footer />
      </div>
    )
  }

  if (userToReset && passwordToReset) {
    return (
      <ResetPassword
        userAlias={userToReset}
        currentPassword={passwordToReset}
        afterReset={({ preferredUsername, password }) => {
          void onUsernameSubmit({
            preferredUsername,
            password,
          })
        }}
      />
    )
  }

  return (
    <div
      className={tw`flex flex-col items-center overflow-hidden rounded-xl bg-white px-[60px] pb-[16px] pt-[60px]`}
    >
      <LogoHeader title={title} />

      <EmailLoginForm
        switchToUsername={() => setLoginStep('username')}
        handleLogin={onEmailSubmit}
        state={getBtnState('email')}
      />

      <UsernameLoginForm
        switchToEmail={() => setLoginStep('email')}
        handleLogin={onUsernameSubmit}
        state={getBtnState('username')}
        applicationBasePath={applicationBasePath}
      />

      <Footer />
    </div>
  )
}
