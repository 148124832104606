import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { useContext, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { match } from 'ts-pattern'
import { setPayerSettings } from '@shared/api/billing'
import Badge from '@shared/components/Badge'
import { Button } from '@shared/components/baseMui/Button'
import ContactCard from '@shared/components/ContactCard/ContactCard'
import Icon from '@shared/components/Icon'
import GlobalContext from '@shared/contexts/GlobalContext'
import { PayerSettingsData } from '@shared/types/billing'
import { Person } from '@shared/types/person'
import { loaded } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import usePayerSettings from '@app/hooks/usePayerSettings'
import { ContactCardSkeleton } from '@app/pages/Contacts/ContactCardSkeleton/ContactCardSkeleton'
import { PaymentDetailsModal } from '@app/pages/Facilities/Billing/Residents/AddPaymentMethod'
import {
  BadgeConfig,
  configureBadges,
  IconType,
} from '@app/pages/Facilities/Billing/Residents/helpers'
import AutoPayCard from '../AutoPayCard'
import StatementDeliveryCard from '../StatementDeliveryCard'

type PayerCardContainerProps = {
  payer: Contact
  person: Person
  multiplePayers: boolean
}

enum PayerCardState {
  CONTACT_CARD = 'CONTACT_CARD',
  STATEMENT_DELIVERY = 'STATEMENT_DELIVERY',
  PAYMENT_DETAILS = 'PAYMENT_DETAILS',
  AUTO_PAY = 'AUTO_PAY',
}
export default function PayerCardContainer({
  payer,
  person,
  multiplePayers,
}: PayerCardContainerProps) {
  const { orgId, facilityId, id: personId } = person
  const contactId = payer.id!
  const { payerSettings, setPayerSettings: updatePayerSettings } =
    usePayerSettings({
      orgId,
      facilityId,
      personId,
      contactId,
    })

  const { setError } = useContext(GlobalContext)
  const [cardState, setCardState] = useState<PayerCardState>(
    PayerCardState.CONTACT_CARD
  )
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  async function handleSubmit(updatedSettings: PayerSettingsData) {
    setIsSubmitting(true)
    try {
      await setPayerSettings({
        orgId,
        facilityId,
        personId,
        contactId,
        updatedSettings,
      })
      updatePayerSettings(loaded(updatedSettings))
      return true
    } catch (e) {
      setError(e)
      return false
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    match([cardState, payerSettings])
      .with([PayerCardState.CONTACT_CARD, { tag: 'Loading' }], () => {
        return (
          <ContactCard contact={payer} linkable={false}>
            <div className="pt-6">
              <Skeleton width={'120px'} />
              <Skeleton width={'100px'} />
              <Skeleton width={'180px'} />
            </div>
          </ContactCard>
        )
      })
      .with(
        [PayerCardState.CONTACT_CARD, { tag: 'Complete' }],
        ([_, settings]) => {
          return (
            <ContactCard contact={payer} linkable={false}>
              <div className="pt-6">
                <BadgeBuilder
                  configs={configureBadges({
                    payer,
                    payerSettings: settings.value,
                  })}
                  onClick={setCardState}
                />
              </div>
            </ContactCard>
          )
        }
      )
      .with(
        [PayerCardState.PAYMENT_DETAILS, { tag: 'Complete' }],
        ([_, settings]) => {
          return (
            <>
              <ContactCard contact={payer} linkable={false}>
                <div className="pt-6">
                  <BadgeBuilder
                    configs={configureBadges({
                      payer,
                      payerSettings: settings.value,
                    })}
                    onClick={setCardState}
                  />
                </div>
              </ContactCard>
              <PaymentDetailsModal
                contact={payer}
                person={person}
                payerSettings={settings.value}
                onClose={() => setCardState(PayerCardState.CONTACT_CARD)}
                submitFn={handleSubmit}
              />
            </>
          )
        }
      )
      .with(
        [PayerCardState.STATEMENT_DELIVERY, { tag: 'Complete' }],
        ([_, settings]) => {
          return (
            <StatementDeliveryCard
              contact={payer}
              payerSettings={settings.value}
              onClose={() => setCardState(PayerCardState.CONTACT_CARD)}
              submitFn={handleSubmit}
              isSubmitting={isSubmitting}
            />
          )
        }
      )
      .with([PayerCardState.AUTO_PAY, { tag: 'Complete' }], ([_, settings]) => {
        return (
          <AutoPayCard
            contact={payer}
            payerSettings={settings.value}
            onClose={() => setCardState(PayerCardState.CONTACT_CARD)}
            submitFn={handleSubmit}
            isSubmitting={isSubmitting}
            multiplePayers={multiplePayers}
          />
        )
      })
      // this state should be unreachable in prod- the badges that allow a user to enter
      // a card state other than ContactCard are only visible once data is loaded
      // HMR can get you into this state in local dev
      .otherwise(() => {
        return <ContactCardSkeleton />
      })
  )
}

function BadgeBuilder({
  configs,
  onClick,
}: {
  configs: BadgeConfig[][]
  onClick: (PayerCardState) => void
}) {
  return (
    <div className="grid grid-cols-1 gap-2">
      {configs.map((rowConfig, j) => (
        <div key={j}>
          {rowConfig.map((c, i) => (
            <Badge
              key={`${j}_${i}`}
              color={c.active ? 'gray-06' : 'yellow'}
              shape="square"
              badgeSize="large"
              className={tw`mr-1 px-[8px] font-medium normal-case`}
              icon={c.iconType}
            >
              {c.text}
            </Badge>
          ))}
          <Button
            buttonStyle="tertiary-fill"
            buttonSize="small"
            className={tw`border border-gray-10 px-2`}
            onClick={() => {
              match(rowConfig[0])
                .with({ iconType: IconType.STATEMENT_DELIVERY }, () => {
                  return onClick(PayerCardState.STATEMENT_DELIVERY)
                })
                .with({ iconType: IconType.PAYMENT_DETAILS }, () => {
                  return onClick(PayerCardState.PAYMENT_DETAILS)
                })
                .with({ iconType: IconType.AUTO_PAY_AMOUNT }, () => {
                  return onClick(PayerCardState.AUTO_PAY)
                })
            }}
          >
            <Icon name="pen" />
          </Button>
        </div>
      ))}
    </div>
  )
}
