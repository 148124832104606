import { OrgFacilitySettings } from '@augusthealth/models/com/august/protos/org_facility_settings'
import { Task } from '@augusthealth/models/com/august/protos/task'
import { useContext, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { upsertSettings } from '@shared/api/orgFacilitySettings'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Badge from '@shared/components/Badge'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import GlobalContext from '@shared/contexts/GlobalContext'
import { taskTitle } from '@shared/utils/task'
import { getSettingsLevel } from '@app/pages/Tools/TaskDefinitions/helpers'

export default function TaskExplanationModal({
  taskSettings,
  onClose,
}: {
  taskSettings: OrgFacilitySettings
  onClose: (shouldRefresh: boolean) => Promise<void>
}) {
  const { setError } = useContext(GlobalContext)
  const [isDeleting, setIsDeleting] = useState(false)

  const titleVerb = useMemo(
    () =>
      taskSettings.settings?.taskDefinition?.template?.descriptionCopy
        ? 'Edit'
        : 'Create',
    []
  )

  const { handleSubmit, register, formState } = useForm<{
    description: string | null
  }>({
    defaultValues: {
      description:
        taskSettings.settings?.taskDefinition?.template?.descriptionCopy ||
        null,
    },
  })

  const settingsLevel = getSettingsLevel(taskSettings)

  async function onSubmit(data: { description: string | null }) {
    try {
      const { createdBy, updatedBy, ...newTask } = {
        ...taskSettings,
        settings: {
          ...taskSettings.settings,
          taskDefinition: {
            ...taskSettings.settings?.taskDefinition,
            template: {
              ...taskSettings.settings?.taskDefinition?.template,
              descriptionCopy: data.description!,
            },
          },
        },
      }

      await upsertSettings(newTask)
      await onClose(true)
    } catch (e) {
      setError(e)
    }
  }

  async function clearDescriptionAndSubmit() {
    setIsDeleting(true)
    const data = { description: null }
    await onSubmit(data)
    setIsDeleting(false)
  }

  const task = taskSettings.settings!.taskDefinition!
  const taskWithTemplateInfo: Task = { taskTemplateInfo: task.template! }
  const taskName = taskTitle(taskWithTemplateInfo)

  function Title() {
    const title = `${titleVerb} Task Explanation for ${taskName}`

    return (
      <>
        <h3>{title}</h3>
        <Badge shape="square" color="purple">
          {settingsLevel}
        </Badge>
      </>
    )
  }

  return (
    <AnimatedPopup onClickBackground={() => onClose(false)} title={<Title />}>
      <form data-testid="modal-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-[32px]">
          <LabelAboveInput htmlFor="description">Description</LabelAboveInput>
          <BasicTextarea {...register('description')} />
        </div>

        <div className="mt-[32px] flex justify-between">
          <div>
            <AnimatedPopupFormFooter
              yesBtn={{}}
              noBtn={{
                action: () => void onClose(false),
              }}
              formState={formState}
            />
          </div>
          <div className="ml-[16px]">
            <AsyncIconButton
              buttonStyle="danger-fill"
              onClick={handleSubmit(clearDescriptionAndSubmit)}
              isLoading={isDeleting}
              disabled={isDeleting}
            >
              Delete
            </AsyncIconButton>
          </div>
        </div>
      </form>
    </AnimatedPopup>
  )
}
