import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { regenerateStatements } from '@shared/api/billing'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import ButtonLink from '@shared/components/ButtonLink'
import { DotDivider, SmallDotDivider } from '@shared/components/DotDivider'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { FeatureFlagNames } from '@shared/constants/feature_flags'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { contactsPathForPerson } from '@shared/legacy_routes'
import { Contact } from '@shared/types/contact'
import { Person } from '@shared/types/person'
import { PersonStats } from '@shared/types/person_stats'
import {
  firstLastName,
  paymentMethod as getPaymentMethod,
} from '@shared/utils/contact'
import { formatDateMessage } from '@shared/utils/date'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { getOrElse, LOADING, mapLoading } from '@shared/utils/loading'
import { ageString, getRoomNumber } from '@shared/utils/person'
import pluralize from '@shared/utils/pluralize'
import { tw } from '@shared/utils/tailwind'
import { isFeatureAllowed } from '@shared/utils/user'
import ContactLink from '@app/components/ContactModalLink'
import { DownloadMenu } from '@app/components/DownloadMenu'
import { useBillingResidentSummaryContext } from '@app/contexts/BillingResidentSummaryContext'
import useProfileImage from '@app/hooks/useProfileImage'
import { payerText } from '../../helpers'
import PayerDetailsModal from './PayerDetailsModal'
import Table from './Table'

type Props = {
  resident: PersonStats
  modifyPaymentInfo: (contact: Contact) => Promise<void>
  onAddInvoice: () => void
  onSendStatement: () => void
  reloadAndDisplayHud: (hudMsg: string) => void
  hasPendingStatement: boolean
}

export default function BillingPersonNavbar(props: Props) {
  const { setError } = useContext(GlobalContext)
  const {
    modifyPaymentInfo,
    resident: { person },
    onAddInvoice,
    onSendStatement,
    reloadAndDisplayHud,
    hasPendingStatement = false,
  } = props
  const { billingSummary } = useBillingResidentSummaryContext()
  const responsibleParty = getOrElse(
    mapLoading(billingSummary, (bs) => bs.responsibleParty),
    undefined
  )
  const payers = getOrElse(
    mapLoading(billingSummary, (bs) => bs.payers),
    []
  )

  const regenerate = async () => {
    try {
      await regenerateStatements(person)
      reloadAndDisplayHud('Statement regenerated!')
    } catch (err) {
      setError(err)
    }
  }

  return (
    <div
      className={tw`flex-start flex flex-col gap-[16px] rounded-[8px] bg-white p-[24px] shadow-card`}
    >
      <div className={tw`flex`}>
        <div className={tw`grow`}>
          <Header
            person={person}
            responsibleParty={responsibleParty}
            payers={payers}
            modifyPaymentInfo={modifyPaymentInfo}
          />
        </div>
        <div>
          <DownloadMenu
            menuItems={[
              {
                id: 'draft-statement-option',
                title: 'Draft statement',
                iconName: 'file-invoice-dollar',
                isDisabled: false,
                isVisible: true,
                onClick: onAddInvoice,
              },
              {
                id: 'refresh-statement-option',
                title: 'Refresh statement',
                iconName: 'arrows-rotate',
                isDisabled: false,
                isVisible: true,
                onClick: regenerate,
              },
              {
                id: 'send-statement-option',
                title: 'Send statement',
                iconName: 'envelope',
                isDisabled: !hasPendingStatement,
                isVisible: true,
                onClick: onSendStatement,
              },
            ]}
            menuProps={{
              buttonProps: {
                cta: 'Resident Actions',
                initialIcon: '',
              },
            }}
          />
        </div>
      </div>
      <Divider />
      <Table billingSummary={billingSummary} />
    </div>
  )
}

function Header({
  person,
  responsibleParty,
  payers,
  modifyPaymentInfo,
}: {
  modifyPaymentInfo: (contact: Contact) => Promise<void>
  person: Person
  responsibleParty: Contact | undefined
  payers: Contact[]
}) {
  return (
    <div className={tw`flex gap-[16px]`}>
      <Avatar person={person} />
      <div className={tw`flex flex-col justify-between`}>
        <div>
          <h1
            data-testid="person-name"
            className={tw`text-[20px] font-[600] leading-4`}
          >
            {getFirstAndLastName(person.name)}
          </h1>
          <ProfileInfo person={person} />
        </div>
        <div>
          <RPInfo person={person} responsibleParty={responsibleParty} />
          <Payers
            modifyPaymentInfo={modifyPaymentInfo}
            person={person}
            payers={payers}
          />
        </div>
      </div>
    </div>
  )
}

function Avatar({ person }: { person: Person }) {
  const { imageURL } = useProfileImage({
    person: {
      tag: 'Complete',
      value: person,
    },
  })

  return (
    <div
      className={tw`flex h-[104px] w-[104px] items-center justify-center rounded-[4px] bg-[color:var(--secondary-10)]`}
    >
      {imageURL === LOADING && (
        <div style={{ width: '184px' }}>
          <SimpleSpinner />
        </div>
      )}
      <img
        src={getOrElse(imageURL, '')}
        alt={`${getFirstAndLastName(person.name)} profile photo`}
        className={tw`h-[104px ] w-[104px] rounded-[4px]`}
      />
    </div>
  )
}

function ProfileInfo({ person }: { person: Person }) {
  return (
    <section>
      <div
        data-testid="person-info"
        className={tw`flex text-[14px] font-[500] leading-4 text-gray-01`}
      >
        {person.birthDate ? (
          <>
            {formatDateMessage(person.birthDate)}
            <span> ({ageString(person)})</span>
          </>
        ) : (
          <>No birthdate on record</>
        )}

        <DotDivider />
        {getRoomNumber(person)}
      </div>
    </section>
  )
}

function RPInfo({
  responsibleParty,
  person,
}: {
  person: Person
  responsibleParty: Contact | undefined
}) {
  if (!responsibleParty) {
    return (
      <div className={tw`font-500 flex items-center text-[14px] text-primary`}>
        <div>
          <i className={tw`fa fa-hands-heart mr-[8px] text-[14px]`} />
          <span className={tw`font-medium text-secondary-07`}>
            No Responsible Person
          </span>
          <DotDivider />
          <Link
            className={tw`color-button-primary m-0 p-0 no-underline`}
            to={contactsPathForPerson(person as Required<Person>)}
          >
            Add one now
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div>
      <span className={tw`text-[14px] font-medium text-gray-07`}>
        <i className={tw`fa fa-hands-heart mr-[8px] text-[14px]`} />
        Responsible Person:{' '}
      </span>
      <ContactLink
        contact={responsibleParty}
        linkStyles={'text-[14px] leading-[16px]'}
        linkContent={firstLastName(responsibleParty)}
        person={person}
      />
    </div>
  )
}

function Payers({
  payers,
  person,
  modifyPaymentInfo,
}: {
  person: Person
  payers: Contact[]
  modifyPaymentInfo: (contact: Contact) => Promise<void>
}) {
  const { user } = useUserContext()

  const viewPayerSettingsInDevelopment = isFeatureAllowed(
    user,
    FeatureFlagNames.PAYER_SETTINGS_DEVELOPMENT
  )

  const [payerModalIsOpen, setPayerModalIsOpen] = useState<boolean>(false)

  if (payers.length === 0) {
    return (
      <div className={tw`font-500 flex items-center text-[14px] text-primary`}>
        <div>
          <i className={tw`fa fa-hands-heart mr-[8px] text-[14px]`} />
          <span className={tw`font-medium text-secondary-07`}>No Payers</span>
        </div>
      </div>
    )
  }

  return (
    <div>
      <span className={tw`text-[14px] font-medium text-gray-07`}>
        <i className={tw`fa fa-hands-holding-dollar mr-[8px] text-[14px]`} />
        {pluralize('Payer', payers.length)}
        {': '}
      </span>
      {viewPayerSettingsInDevelopment ? (
        <>
          <ButtonLink
            className="text-sm"
            onClick={() => setPayerModalIsOpen(true)}
          >
            {payerText(payers)}
          </ButtonLink>
          <PayerDetailsModal
            open={payerModalIsOpen}
            onClose={() => setPayerModalIsOpen(false)}
            person={person}
            payers={payers}
          />
        </>
      ) : (
        payers.map((payer, index) => (
          <React.Fragment key={index}>
            <ContactLink
              key={payer.id}
              contact={payer}
              linkStyles={'text-[14px] leading-[16px]'}
              linkContent={nameWithPaymentMethod(payer)}
              person={person}
            >
              <AsyncIconButton
                buttonSize={'small'}
                width={'100%'}
                buttonStyle={'tertiary-fill'}
                className={tw`mt-[20px]`}
                onClick={() => modifyPaymentInfo(payer)}
              >
                {getPaymentMethod(payer) ? 'Change' : 'Add'} Payment Information
              </AsyncIconButton>
            </ContactLink>
            {index !== payers.length - 1 && <SmallDotDivider />}
          </React.Fragment>
        ))
      )}
    </div>
  )
}

function nameWithPaymentMethod(contact: Contact) {
  let payerText = firstLastName(contact)
  const paymentMethod = getPaymentMethod(contact)
  if (paymentMethod) {
    payerText += ` (${paymentMethod})`
  }

  return payerText
}

function Divider() {
  return <div className={tw`h-[1px] w-full bg-gray-11 opacity-50`} />
}
