import {
  SummarySectionList,
  SummarySectionListItem,
} from '@shared/components/LinkCard/SummarySection'
import {
  RoutineAdministration,
  RoutineAdministrationProgressType,
} from '@shared/types/careapp'
import { tw } from '@shared/utils/tailwind'
import { getIconNameAndClassByStatus } from './helpers'

export default function RoutineAdministrationsStatusSummary({
  routineAdministrationMap,
}: {
  routineAdministrationMap: Partial<
    Record<RoutineAdministrationProgressType, RoutineAdministration[]>
  >
  displayAdministrationDate?: boolean
}) {
  return (
    <div>
      {Object.entries(routineAdministrationMap).map(
        ([key, administrations]) => {
          const { barColor, iconName, iconClass } = getIconNameAndClassByStatus(
            key as RoutineAdministrationProgressType
          )
          const title = `${key} (${administrations.length})`.toUpperCase()

          if (administrations.length) {
            return (
              <SummarySectionList key={title} title={title} barColor={barColor}>
                {administrations.map((administration) => {
                  const { id, orderName } = administration

                  // TO-DO Still need to handle slightly different display for exception based on Figma
                  return (
                    <SummarySectionListItem
                      key={`summary-item-${key}-${id}`}
                      iconName={iconName}
                      iconClass={iconClass}
                    >
                      <span className={tw`font-semibold text-secondary-04`}>
                        {orderName}
                      </span>
                    </SummarySectionListItem>
                  )
                })}
              </SummarySectionList>
            )
          } else return null
        }
      )}
    </div>
  )
}
