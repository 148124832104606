import { ClassName } from 'react-pdf/dist/cjs/shared/types'
import { StylesConfig } from 'react-select'
import {
  IconStyledSelect,
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { ShiftOccurrence } from '@shared/types/careapp'
import { twx } from '@shared/utils/tailwind'

function getShiftLabel(shiftOccurrence: ShiftOccurrence) {
  return shiftOccurrence.shift.name
}

function getShiftId(shiftOccurrence: ShiftOccurrence) {
  return shiftOccurrence.shift.id
}

function shiftOccurrenceOptions(
  shiftOccurrences: ShiftOccurrence[]
): OptionTypeBase<ShiftOccurrence>[] {
  return shiftOccurrences.map((shiftOccurrence) => ({
    value: shiftOccurrence,
    label: getShiftLabel(shiftOccurrence),
  }))
}

export function ShiftOccurrenceSelect({
  selectedShiftOccurrence,
  setSelectedShiftOccurrence,
  shiftOccurrences,
  disabled = false,
  className,
  styles,
}: {
  selectedShiftOccurrence?: ShiftOccurrence
  setSelectedShiftOccurrence: (shiftOccurrence: ShiftOccurrence) => void
  shiftOccurrences: ShiftOccurrence[]
  disabled?: boolean
  className?: ClassName
  styles?: StylesConfig
}) {
  const options = shiftOccurrenceOptions(shiftOccurrences)
  const value = selectedShiftOccurrence
    ? options.find(
        (opt) => getShiftId(opt.value) === getShiftId(selectedShiftOccurrence)
      )
    : undefined

  return (
    <IconStyledSelect
      inputId="shiftSelect"
      name="shiftSelect"
      iconName="fa-solid fa-clock"
      className={twx(className?.toString())}
      isDisabled={disabled}
      options={options}
      value={value}
      onChange={(shiftOccurrence: OptionTypeBase<ShiftOccurrence>) =>
        setSelectedShiftOccurrence(shiftOccurrence.value)
      }
      styles={styles}
    />
  )
}
