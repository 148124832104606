import { fetchJson } from '@shared/api/request'
import { apiUrl, facilityUrl } from '@shared/api/urls'
import { FacilityProgressRoutines } from '@shared/types/careapp'
import { FacilityIds } from '@shared/types/facility'

type FacilityCareProgress = { data: FacilityProgressRoutines }

export type FetchFacilityCareProgress = {
  facility: FacilityIds
  careGroupId?: string
  date?: string
  shiftId?: string
}

export async function fetchFacilityCareProgress({
  facility: { orgId, id: facilityId },
  careGroupId,
  date,
  shiftId,
}: Pick<
  FetchFacilityCareProgress,
  'facility' | 'careGroupId' | 'date' | 'shiftId'
>) {
  return fetchJson<FacilityCareProgress>(
    apiUrl(facilityUrl(orgId, facilityId), '/care/progress', {
      careGroupId,
      date,
      shiftId,
    }),
    { method: 'GET' }
  ).then((res: FacilityCareProgress) => res.data)
}
