import { useContext, useState } from 'react'
import { issueStatementForResident } from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { BillingEvent, RecurringCharge } from '@shared/types/billing'
import { PersonPageProps } from '@shared/types/person'
import {
  BillingModalType,
  convertChargeToFakeTransaction,
  getPendingInvoiceTransaction,
  isPendingOneTimeCharge,
  isPendingRecurringCharge,
} from '@shared/utils/billing'
import { getOrElse } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import HUD from '@app/components/HUD'
import { BillingResidentSummaryContextProvider } from '@app/contexts/BillingResidentSummaryContext'
import useBillingEvents from '@app/hooks/useBillingEvents'
import useRecurringCharges from '@app/hooks/useBillingRecurringCharges'
import useBillingRelatedResidentEvents from '@app/hooks/useBillingRelatedResidentEvents'
import useBillingResidentSummary from '@app/hooks/useBillingResidentSummary'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import AddInvoiceModal from '@app/pages/Facilities/Billing/Modals/InvoiceModals/AddStatementModal'
import ChargeModal from '@app/pages/Facilities/Billing/Modals/TransactionModals/ChargeModal'
import CreditModal from '@app/pages/Facilities/Billing/Modals/TransactionModals/CreditModal'
import DeleteConfirmModal from '@app/pages/Facilities/Billing/Modals/TransactionModals/DeleteConfirmModal'
import EditRecurringChargeModal from '@app/pages/Facilities/Billing/Modals/TransactionModals/EditRecurringChargeModal'
import EndRecurringChargeModal from '@app/pages/Facilities/Billing/Modals/TransactionModals/EndRecurringChargeModal'
import AddPaymentMethod from '@app/pages/Facilities/Billing/Residents/AddPaymentMethod'
import IssueIndividualStatementModal from '../../Modals/InvoiceModals/IssueIndividualStatementModal'
import BillingPersonNavbar from './BillingPersonNavbar'
import TransactionsTable from './BillingTransactionsTable'
import RecurringChargesTable from './RecurringChargesTable'
import ResidentEvents from './ResidentEvents'
import { filterOutInvalidResidentEvents } from './ResidentEvents/helpers'

type ModalData = {
  residentIds: string[]
  modalType: BillingModalType
  charge?: RecurringCharge
  transaction?: BillingEvent
} | null

export default function Resident({
  match: { params },
  closeChargeModal,
  showChargeModal,
}: PersonPageProps & {
  closeChargeModal: () => void
  showChargeModal?: 'ADD_CHARGE' | 'RECORD_CREDIT'
}) {
  const { orgId, facilityId, personId } = params
  const { allPeople } = useCurrentFacility()
  const { setError } = useContext(GlobalContext)
  const [modalData, setModalData] = useState<ModalData>(null)
  const [hudText, setHudText] = useState<string>('')
  const { recurringCharges, reloadRecurringCharges } = useRecurringCharges({
    orgId,
    facilityId,
    id: personId,
  })
  const { billingEvents, reloadBillingEvents } = useBillingEvents({
    orgId,
    facilityId,
    id: personId,
  })

  const resident = getOrElse(allPeople, []).find(
    (r) => r.person.id === personId
  )

  const { billingSummary, reloadBillingSummary } = useBillingResidentSummary({
    orgId,
    facilityId,
    personId,
  })

  const { residentEvents, completeAndReloadResidentEvents } =
    useBillingRelatedResidentEvents({
      orgId,
      facilityId,
      id: personId,
    })

  if (!resident) return null

  const filteredEvents = filterOutInvalidResidentEvents({
    residentEvents: getOrElse(residentEvents, []),
    person: resident.person,
  })

  const modalCloseFunc = () => setModalData(null)

  const reloadAndDisplayHud = async (message: string) => {
    setHudText(message)
    await reloadBillingSummary()
    await reloadBillingEvents()
    await reloadRecurringCharges()
  }
  const getHudDeleteMsg = (t: BillingEvent) => {
    if (isPendingOneTimeCharge(t)) return 'Charge deleted!'
    if (isPendingRecurringCharge(t)) return 'Charge cancelled!'

    return 'Done!'
  }

  const pendingStatement = getPendingInvoiceTransaction(
    getOrElse(billingEvents, [])
  )

  const sendStatement = async () => {
    try {
      if (pendingStatement) {
        await issueStatementForResident({
          personIds: { orgId, facilityId, id: personId },
          statementId: pendingStatement.id,
        })
        await reloadAndDisplayHud('Statement sent!')
      }
    } catch (err) {
      setError(err)
    }
  }

  const handleSendStatement = async () => {
    if (filteredEvents.length > 0) {
      setModalData({
        residentIds: [resident.person.id],
        modalType: 'SEND_STATEMENT',
      })
    } else {
      await sendStatement()
    }
  }

  return (
    <BillingResidentSummaryContextProvider value={{ billingSummary }}>
      <BillingPersonNavbar
        resident={resident}
        modifyPaymentInfo={async (contact) => {
          setModalData({
            residentIds: [resident.person.id],
            modalType: { tag: 'ADD_PAYMENT_METHOD_MODAL', contact },
          })
        }}
        onAddInvoice={() => {
          setModalData({
            residentIds: [resident.person.id],
            modalType: 'ADD_INVOICE',
          })
        }}
        onSendStatement={handleSendStatement}
        reloadAndDisplayHud={reloadAndDisplayHud}
        hasPendingStatement={Boolean(pendingStatement)}
      />
      <ResidentEvents
        filteredEvents={filteredEvents}
        onDismiss={completeAndReloadResidentEvents}
      />
      <div className={tw`relative z-10`}>
        <TransactionsTable
          personIds={{ orgId, facilityId, id: personId }}
          openTransactionModal={(
            transaction: BillingEvent,
            modalType: BillingModalType
          ) => {
            const charge = getOrElse(recurringCharges, []).find(
              (c) => c.meta.id.toString() === transaction.id
            )

            setModalData({
              residentIds: [resident.person.id],
              modalType,
              charge,
              transaction,
            })
          }}
          billingTransactions={billingEvents}
        />
      </div>
      <RecurringChargesTable
        openChargeModal={(
          charge: RecurringCharge,
          modalType: BillingModalType
        ) => {
          setModalData({
            residentIds: [resident.person.id],
            modalType,
            transaction: convertChargeToFakeTransaction(charge), // for DELETE_CHARGE_OR_CREDIT only
            charge,
          })
        }}
        billingCharges={recurringCharges}
      />
      {(showChargeModal === 'ADD_CHARGE' ||
        modalData?.modalType === 'ADD_CHARGE') && (
        <ChargeModal
          closeFn={() => {
            modalCloseFunc()
            closeChargeModal()
          }}
          doneFn={() => reloadAndDisplayHud('Charge created!')}
          residentIds={[resident.person.id]}
        />
      )}
      {(showChargeModal === 'RECORD_CREDIT' ||
        modalData?.modalType === 'ADD_CREDIT') && (
        <CreditModal
          closeFn={() => {
            modalCloseFunc()
            closeChargeModal()
          }}
          doneFn={() => reloadAndDisplayHud('Credit created!')}
          residentIds={[resident.person.id]}
        />
      )}
      {typeof modalData?.modalType === 'object' &&
        modalData?.modalType?.tag === 'ADD_PAYMENT_METHOD_MODAL' &&
        billingSummary.tag === 'Complete' && (
          <AddPaymentMethod
            orgId={orgId}
            facilityId={facilityId}
            personId={personId}
            contact={modalData?.modalType.contact}
            closeFn={modalCloseFunc}
            reloadBillingSummary={reloadBillingSummary}
          />
        )}
      {modalData?.modalType === 'DELETE_CHARGE_OR_CREDIT' &&
        modalData?.transaction && (
          <DeleteConfirmModal
            closeFn={modalCloseFunc}
            doneFn={() =>
              reloadAndDisplayHud(getHudDeleteMsg(modalData.transaction!))
            }
            personIds={{
              orgId,
              facilityId,
              id: modalData.residentIds[0],
            }}
            transaction={modalData.transaction}
          />
        )}
      {modalData?.modalType === 'EDIT_CHARGE' && modalData.charge && (
        <EditRecurringChargeModal
          closeFn={modalCloseFunc}
          doneFn={() => reloadAndDisplayHud('Charge updated!')}
          personIds={{ orgId, facilityId, id: personId }}
          charge={modalData.charge}
        />
      )}
      {modalData?.modalType === 'END_CHARGE' && modalData.charge && (
        <EndRecurringChargeModal
          closeFn={modalCloseFunc}
          doneFn={() => reloadAndDisplayHud('Charge ended!')}
          personIds={{ orgId, facilityId, id: personId }}
          charge={modalData.charge}
        />
      )}
      {modalData?.modalType === 'ADD_INVOICE' && (
        <AddInvoiceModal
          closeFn={modalCloseFunc}
          doneFn={() => reloadAndDisplayHud('Statement created!')}
          facilityId={facilityId}
          orgId={orgId}
          residentIds={[personId]}
          hideResidentDropdown
        />
      )}
      {modalData?.modalType === 'SEND_STATEMENT' && pendingStatement?.id && (
        <IssueIndividualStatementModal
          closeFn={modalCloseFunc}
          doneFn={sendStatement}
          facilityId={facilityId}
          orgId={orgId}
          statementId={pendingStatement.id}
        />
      )}
      {hudText && <HUD onExpire={() => setHudText('')}>{hudText}</HUD>}
    </BillingResidentSummaryContextProvider>
  )
}
