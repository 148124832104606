import { AugustInitialAppraisal } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import { AppraisalSettings_AppraisalCategory } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { isEqual } from 'lodash'
import { RequiredLabel } from '@shared/components/Labels'
import { Person } from '@shared/types/person'
import {
  getValuesByCategory,
  makeCheckboxSections,
  matchOnCategoryAndCustomKey,
} from '@shared/utils/residentAssessment'
import AugustFieldDetail from '@app/pages/Tasks/ResidentAssessment/AssessmentPage/CheckboxSections/AugustFieldDetail'
import styles from '@app/pages/Tasks/ResidentAssessment/styles.module.css'
import {
  BehaviorCustomization,
  checkboxSectionModifiers,
} from '@app/pages/Tasks/ResidentAssessment/useAssessmentModification'
import { AssessmentChange } from '../types'
import { CheckboxDetail } from './CheckboxDetail'
import DropdownDetail from './DropdownDetail'
import TextareaDetail from './TextareaDetail'
import TextboxDetail from './TextboxDetail'

export default function CheckboxSections({
  person,
  mode,
  assessment = {},
  category,
  disabled,
  onChange,
  behaviorCustomization = {},
}: {
  person: Person
  mode: 'edit' | 'edit-sans-august-fields' | 'view'
  assessment?: AugustInitialAppraisal
  category: AppraisalSettings_AppraisalCategory
  disabled: boolean
  onChange: (change: AssessmentChange) => void
  behaviorCustomization?: BehaviorCustomization
}) {
  const { categoryKey, customKey } = category
  const { details: selectedDetails = [] } = getValuesByCategory({
    assessment,
    category,
  })
  const selectedCustomDetails =
    assessment.customDetails?.find((cd) =>
      matchOnCategoryAndCustomKey(cd, { categoryKey, customKey })
    )?.fields ?? []

  const orderedCheckboxSections = makeCheckboxSections(category)
  const { disabledGroups = [], disabledDetails = [] } = behaviorCustomization
  const uniqueKey = customKey ? `${categoryKey}-${customKey}` : categoryKey

  return (
    <>
      {[...orderedCheckboxSections.entries()].map((v, i) => {
        const [name, details] = v
        const keyPrefix = `${categoryKey}-${name}`

        // Claiborne customization
        const { showRequiredLabel, disabledDetailsForCategory, nameMatches } =
          checkboxSectionModifiers({
            person,
            behaviorCustomization,
            category,
            sectionName: name,
            details,
          })

        return (
          <div key={`${keyPrefix}-${i}`}>
            <h3 className={styles.h3}>
              {name}
              {showRequiredLabel && <RequiredLabel showError={true} />}
            </h3>
            {details.map((detailData) => {
              const isDisabledDetail = disabledDetailsForCategory.some((d) =>
                isEqual(d, detailData)
              )

              if (detailData.tag === 'DetailWithAugustField') {
                const augustFieldType = detailData.value.augustFieldType!
                return (
                  <AugustFieldDetail
                    key={`${uniqueKey}-${augustFieldType}`}
                    initialPerson={person}
                    augustField={augustFieldType}
                    mode={mode}
                    assessment={assessment}
                  />
                )
              }

              /**
               * When this component is rendered from routines or service plans,
               * we don't support editing august fields. Only the AugustFieldDetail
               * component cares about this mode, so we simplify to edit/view here.
               */
              const simpleMode =
                mode === 'edit-sans-august-fields' ? 'edit' : mode

              if (detailData.tag === 'DetailWithCheckbox') {
                return (
                  <CheckboxDetail
                    category={category}
                    detailData={detailData}
                    disabled={disabled || nameMatches || isDisabledDetail}
                    key={`${uniqueKey}-${detailData.value.description}-${i}`}
                    keyPrefix={keyPrefix}
                    onChange={onChange}
                    selectedCustomDetails={selectedCustomDetails}
                    selectedDetails={selectedDetails}
                    selfUpdating={
                      disabledDetails.length > 0 || disabledGroups.length > 0
                    }
                  />
                )
              }

              if (detailData.tag === 'DetailWithTextbox') {
                return (
                  <TextboxDetail
                    detailData={detailData}
                    selectedCustomDetails={selectedCustomDetails}
                    category={category}
                    disabled={disabled || isDisabledDetail}
                    key={`${uniqueKey}-textbox-${i}-${detailData.value.id}`}
                    onChange={onChange}
                  />
                )
              }

              if (detailData.tag === 'DetailWithTextarea') {
                return (
                  <TextareaDetail
                    detailData={detailData}
                    selectedCustomDetails={selectedCustomDetails}
                    category={category}
                    disabled={disabled || isDisabledDetail}
                    key={`${uniqueKey}-textarea-${i}-${detailData.value.id}`}
                    onChange={onChange}
                    mode={simpleMode}
                  />
                )
              }

              return (
                <DropdownDetail
                  allSelectedDetails={selectedDetails}
                  selectedCustomDetails={selectedCustomDetails}
                  category={category}
                  detailData={detailData}
                  disabled={disabled || nameMatches || isDisabledDetail}
                  key={`${uniqueKey}-${detailData.value.detailMetadata.name}-${i}`}
                  onChange={onChange}
                  selfUpdating={
                    disabledDetails.length > 0 || disabledGroups.length > 0
                  }
                />
              )
            })}
          </div>
        )
      })}
    </>
  )
}
