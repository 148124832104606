import { Contact_ContactRelationship as CR } from '@augusthealth/models/com/august/protos/contact'
import { useContext, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import GlobalContext from '@shared/contexts/GlobalContext'
import { tw } from '@shared/utils/tailwind'
import {
  createContact,
  createPrimaryPhysicianContact,
  updateContact,
  updateContactGeneralPractitioner,
} from '@app/api/contacts'
import DetailsTab from './DetailsTab'
import {
  contactFromFormValues,
  defaultValuesFromContact,
  hasPrimaryCareRoleChanged,
} from './helpers'
import RolesTab from './RolesTab'
import {
  AddContactModalProps,
  ContactFormData,
  ContactModalProps,
  EditContactModalProps,
} from './types'

export function AddContactModal(props: AddContactModalProps) {
  const { defaultFormValues, onClose, person } = props
  const { setError } = useContext(GlobalContext)
  const methods = useForm<ContactFormData>({
    mode: 'onChange',
    defaultValues: defaultFormValues
      ? defaultFormValues
      : {
          primaryRelationship: CR.CONTACT_RELATIONSHIP_PERSONAL,
        },
  })
  const onSubmit = async (formData: ContactFormData) => {
    let response
    try {
      const contact = contactFromFormValues(formData)
      const contactProps = {
        contact,
        facilityId: person.facilityId!,
        orgId: person.orgId!,
        personId: person.id!,
      }
      if (
        (formData.roles || []).some(
          (r) => r === CR.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER
        )
      ) {
        response = await createPrimaryPhysicianContact(contactProps)
      } else {
        response = await createContact(contactProps)
      }
      await onClose(response)
    } catch (e) {
      setError(e)
    }
  }
  return (
    <FormProvider {...methods}>
      <ContactModal {...props} onSubmit={onSubmit} />
    </FormProvider>
  )
}

export function EditContactModal(props: EditContactModalProps) {
  const { setError } = useContext(GlobalContext)
  const methods = useForm<ContactFormData>({
    mode: 'onChange',
    defaultValues: defaultValuesFromContact(props.contact),
  })

  const onSubmit = async (formData: ContactFormData) => {
    try {
      const updatedContact = contactFromFormValues(formData)
      const sharedProps = {
        facilityId: props.person.facilityId!,
        orgId: props.person.orgId!,
        personId: props.person.id!,
      }
      if (hasPrimaryCareRoleChanged(formData, props.contact)) {
        await updateContactGeneralPractitioner({
          contactId: +props.contact.id!,
          ...sharedProps,
        })
      }
      const res = await updateContact({
        contact: { ...updatedContact, id: props.contact.id },
        ...sharedProps,
      })
      await props.onClose(res)
    } catch (e) {
      setError(e)
    }
  }
  return (
    <FormProvider {...methods}>
      <ContactModal {...props} isEditing={true} onSubmit={onSubmit} />
    </FormProvider>
  )
}

function ContactModal({
  contact = undefined,
  isEditing = false,
  onClose,
  onSubmit,
  person,
}: ContactModalProps) {
  const methods = useFormContext()

  const [showContactRoles, setShowContactRoles] = useState(isEditing)
  const [contactToEdit, setContactToEdit] = useState(contact)

  return (
    <Modal
      id={'contact-modal'}
      open={true}
      onClose={() => onClose(false)}
      contentClassName={tw`w-defaultModal min-w-defaultModal max-w-defaultModal`}
    >
      <form
        data-testid="contact-form"
        onSubmit={(event) => {
          event.preventDefault()
          event.stopPropagation()
          void methods.handleSubmit(onSubmit)()
        }}
      >
        {!showContactRoles && (
          <FormProvider {...methods}>
            <DetailsTab
              contact={contact}
              onClose={onClose}
              setContactToEdit={setContactToEdit}
              setShowContactRoles={setShowContactRoles}
              person={person}
            />
          </FormProvider>
        )}
        {showContactRoles && (
          <RolesTab
            contact={contactToEdit}
            methods={methods}
            isEditing={isEditing}
            onClose={onClose}
            setShowContactRoles={setShowContactRoles}
            person={person}
          />
        )}
      </form>
    </Modal>
  )
}
