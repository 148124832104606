import {
  shiftOccurrence as getCurrentShiftOccurence,
  shiftOccurrences as getShiftOccurrences,
} from '@careapp/utils/shiftOccurrence'
import { Option } from 'effect'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { CareGroupsSelect } from '@shared/components/Selects/CareGroupsSelect'
import { ShiftOccurrenceSelect } from '@shared/components/Selects/ShiftOccurrenceSelect'
import useFacilityCareProgress from '@shared/hooks/useFacilityCareProgress'
import { CareGroup } from '@shared/types/care_group'
import { IsoDate, ShiftOccurrence } from '@shared/types/careapp'
import { Facility } from '@shared/types/facility'
import { formatIsoDate, getDateString } from '@shared/utils/date'
import { getOrElse } from '@shared/utils/loading'
import { fromEffectOption, isNone } from '@shared/utils/option'
import { tw } from '@shared/utils/tailwind'
import Content from '@app/components/generic/Content'
import PersonPageTitle from '@app/components/PersonPageTitle'
import Warning from '@app/components/Warning'
import ResidentRoutines from './ResidentRoutines'
import RoutineCharts from './RoutineCharts'

export default function Progress({ facility }: { facility: Facility }) {
  const { timeZone } = facility
  const now = new Date()
  const [selectedDate, setSelectedDate] = useState<Date>(now)
  const [selectedShiftOccurrence, setSelectedShiftOccurrences] =
    useState<ShiftOccurrence>()
  const [selectedCareGroup, setSelectedCareGroup] = useState<CareGroup>()
  const { facilityCareProgress } = useFacilityCareProgress({
    facility,
    date: formatIsoDate(selectedDate),
    careGroupId: selectedCareGroup?.id,
  })
  const {
    careGroups,
    people,
    shifts,
    routineAdministrations: routineAdministrationsForDate,
  } = getOrElse(facilityCareProgress, {
    date: getDateString(now) as IsoDate,
    careGroups: [],
    shifts: [],
    people: [],
    routineAdministrations: [],
  })

  useEffect(() => {
    if (!selectedShiftOccurrence) {
      const currentShift = getCurrentShiftOccurence({
        shifts,
        date: now,
        timeZone,
      })
      const optionShift = fromEffectOption(currentShift)
      setSelectedShiftOccurrences(isNone(optionShift) ? undefined : optionShift)
    }
  }, [shifts])

  if (
    !facilityCareProgress ||
    facilityCareProgress.tag === 'Loading' ||
    !selectedShiftOccurrence
  ) {
    return <SimpleSpinner />
  }

  if (facilityCareProgress.tag === 'Error') {
    const errorMsg =
      facilityCareProgress.value.json?.errors?.[0].message || 'Unknown error!'
    return (
      <Content className={tw`mt-[32px]`}>
        <PersonPageTitle title="Progress" />
        <Warning>{errorMsg}</Warning>
      </Content>
    )
  }

  const shiftOccurrences = getShiftOccurrences({
    shifts,
    date: now,
    timeZone,
  })
  const routineAdministrationsForSelectedShift =
    routineAdministrationsForDate.filter((r) => {
      return selectedShiftOccurrence.shift.id === r.shiftId
    })

  return (
    <Content className={tw`mt-[32px]`}>
      <PersonPageTitle title="Progress" withBorder={false}>
        <div className={tw`flex`}>
          <DatePicker
            wrapperClassName={tw`w-[174px]`}
            selected={selectedDate}
            onChange={(d: Date) => setSelectedDate(d)}
            customInput={<CalendarInput className={tw`w-[174px]`} />}
          />
          <ShiftOccurrenceSelect
            className={tw`ml-[12px] min-w-[174px]`}
            selectedShiftOccurrence={selectedShiftOccurrence}
            shiftOccurrences={shiftOccurrences}
            setSelectedShiftOccurrence={setSelectedShiftOccurrences}
          />
          <CareGroupsSelect
            className={tw`ml-[12px] min-w-[174px]`}
            selectedCareGroup={Option.fromNullable(selectedCareGroup)}
            careGroups={careGroups}
            setSelectedCareGroup={(careGroup) =>
              setSelectedCareGroup(Option.getOrUndefined(careGroup))
            }
          />
        </div>
      </PersonPageTitle>
      <RoutineCharts
        allRoutineAdministrations={routineAdministrationsForDate}
        selectedShiftOccurrence={selectedShiftOccurrence}
        shiftOccurrences={shiftOccurrences}
        setShiftId={(newShiftId: string) => {
          setSelectedShiftOccurrences(
            shiftOccurrences.find((s) => s.shift.id === newShiftId)
          )
        }}
        facilityTimeZone={timeZone}
      />
      <ResidentRoutines
        careAppPeople={people}
        routineAdministrations={routineAdministrationsForSelectedShift}
        facilityTimeZone={timeZone}
      />
    </Content>
  )
}
