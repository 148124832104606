import { Option } from 'effect'
import { ClassName } from 'react-pdf/dist/cjs/shared/types'
import { StylesConfig } from 'react-select'
import {
  IconStyledSelect,
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { CareGroup } from '@shared/types/care_group'
import { twx } from '@shared/utils/tailwind'

const ALL_CARE_GROUPS = { value: Option.none(), label: 'All care groups' }

function careGroupOptions(
  careGroups: CareGroup[]
): OptionTypeBase<Option.Option<CareGroup>>[] {
  return [
    ALL_CARE_GROUPS,
    ...careGroups.map((careGroup) => {
      return { value: Option.some(careGroup), label: careGroup.name ?? '' }
    }),
  ]
}

export function CareGroupsSelect({
  className,
  selectedCareGroup,
  careGroups,
  setSelectedCareGroup,
  disabled = false,
  styles,
}: {
  selectedCareGroup: Option.Option<CareGroup>
  careGroups: CareGroup[]
  setSelectedCareGroup: (careGroup: Option.Option<CareGroup>) => void
  disabled?: boolean
  className?: ClassName
  styles?: StylesConfig
}) {
  return (
    <IconStyledSelect
      inputId="careGroupSelector"
      name="careGroupSelector"
      iconName="fa-solid fa-people-group"
      className={twx(className?.toString())}
      isDisabled={disabled}
      options={careGroupOptions(careGroups)}
      value={
        Option.isNone(selectedCareGroup)
          ? ALL_CARE_GROUPS
          : {
              value: selectedCareGroup,
              label: selectedCareGroup.value.name,
            }
      }
      onChange={(careGroupOption: OptionTypeBase<Option.Option<CareGroup>>) =>
        setSelectedCareGroup(careGroupOption.value)
      }
      styles={styles}
    />
  )
}
