import {
  ContactPoint,
  ContactPoint_ContactPointUse,
} from '@augusthealth/models/com/august/protos/contact_point'
import { ValidDate } from '@augusthealth/models/com/august/protos/date'
import {
  PaymentBillingStrategy,
  PaymentProduct,
  PaymentSubscription,
} from '@augusthealth/models/com/august/protos/payment'
import { ChangeEvent, useContext, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import { createFacility, mergePatchFacility } from '@shared/api/facility'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import {
  BasicCheckbox,
  BasicInput,
} from '@shared/components/BasicInput/BasicInput'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { ControlledLabelledCalendarInput } from '@shared/components/CalendarInput/ControlledLabelledCalendarInput'
import {
  LabelAboveInput,
  requiredLabel,
  requiredWhenError,
} from '@shared/components/Labels'
import StyledSelect, {
  CreatableStyledSelect,
} from '@shared/components/Selects/StyledSelect'
import {
  EmailInput,
  FaxInput,
  PhoneInput,
} from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import { FeatureFlagNames } from '@shared/constants/feature_flags'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import {
  formatSingleLineAddress,
  getAddressFromGeocoder,
  getAddressPartFromGeocoder,
} from '@shared/utils/address'
import { isEmptyObject } from '@shared/utils/common'
import {
  getEmailValue,
  getFaxValue,
  getPhoneValue,
} from '@shared/utils/contactPoint'
import {
  fromDateMessageToDate,
  fromDateToDateMessage,
} from '@shared/utils/date'
import {
  ProductOptions,
  removeEmptyPaymentSubscription,
} from '@shared/utils/payment'
import { EMAIL_REGX } from '@shared/utils/regex'
import { tw } from '@shared/utils/tailwind'
import {
  getCountryCode,
  getCountryName,
  getTimeZoneOptions,
} from '@shared/utils/Timezones/utils'
import {
  isSuperUser as getIsSuperUser,
  isFeatureAllowed,
} from '@shared/utils/user'
import MoneyInReactHookForm from '@app/components/MoneyInput/MoneyInReactHookForm'
import usePlacesAutocomplete from '@app/hooks/usePlacesAutocomplete'
import {
  getOnEmailChange,
  getOnFaxChange,
  getOnOmbudsmanPhoneChange,
  getOnPhoneChange,
} from './helpers'

type Props = {
  facility: Facility | undefined
  organization: Organization
  onClose: () => void
  onSave: () => Promise<void>
}

type FacilityForm = Omit<Facility, 'id' | 'orgId'>

function isPlatform(p?: PaymentProduct) {
  return p === PaymentProduct.PAYMENT_PRODUCT_PLATFORM
}

export default function FacilityFormPopup(props: Props) {
  const { facility: defaultF, organization, onClose, onSave } = props
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const isSuperUser = getIsSuperUser(user)
  const {
    control,
    handleSubmit,
    getValues,
    register,
    setError: setFormError,
    setValue,
    watch,
    formState,
    formState: { errors },
  } = useForm<FacilityForm>({
    defaultValues: defaultF,
  })
  const isEditMode = defaultF !== undefined
  const [addressPlainText, setAddressPlainText] = useState<string | null>(null)
  const [country, setCountry] = useState<string>(
    getCountryName(defaultF?.timeZone)
  )

  const inputRef = useRef<HTMLInputElement>(null)
  usePlacesAutocomplete({
    inputRef,
    callback: (place) => {
      const addressComponents = place?.address_components || []
      const address = getAddressFromGeocoder(addressComponents)
      const newCountry = getAddressPartFromGeocoder(
        addressComponents,
        'country'
      )

      setValue('address', address)
      setAddressPlainText(null)
      if (newCountry !== country) {
        setCountry(newCountry)
        setValue('timeZone', '')
      }
    },
  })

  const highlightErrorInput = (fieldName: string) => {
    const emailCollection = document.getElementsByName(fieldName)
    if (emailCollection.length) {
      emailCollection[0].focus()
    }
  }

  const onSubmit = async (formData: FacilityForm) => {
    const email = getEmailValue(formData.telecom)

    if (addressPlainText?.trim().length === 0) {
      setFormError('address', { message: 'Required Field' })
      highlightErrorInput('address')
    } else if (email && !EMAIL_REGX.test(email)) {
      setFormError('telecom', { message: 'Invalid Email' })
      highlightErrorInput('email')
    }

    try {
      const facility = removeEmptyPaymentSubscription({
        id: defaultF?.id,
        orgId: organization.id,
        ...formData,
      })

      const product = formData.paymentSubscription?.product
      if (product && !isPlatform(product)) {
        // Set Unit Count to 1 when product is Move In or Board and Care
        facility.paymentSubscription!.unitCount = 1
      }

      if (addressPlainText && addressPlainText.trim().length > 0) {
        facility.address = {
          text: addressPlainText,
          // @ts-ignore
          line: null,
          // @ts-ignore
          city: null,
          // @ts-ignore
          state: null,
          // @ts-ignore
          postalCode: null,
        }
      }

      facility.emarSettings = {
        isActive: formData.emarSettings?.isActive ?? false,
        goLiveMedPassDate: formData.emarSettings?.isActive
          ? formData.emarSettings.goLiveMedPassDate
          : undefined,
        usesStrictTiming: formData.emarSettings?.usesStrictTiming ?? false,
      }

      facility.billingSettings = {
        isActive: formData.billingSettings?.isActive ?? false,
        goLiveDate: formData.billingSettings?.isActive
          ? formData.billingSettings.goLiveDate
          : undefined,
      }

      facility.careAppSettings = {
        isActive: formData.careAppSettings?.isActive ?? false,
        goLiveDate: formData.careAppSettings?.isActive
          ? formData.careAppSettings.goLiveDate
          : undefined,
      }

      const {
        emarSettings,
        billingSettings,
        careAppSettings,
        ...primaryFacilityData
      } = formData

      const writeableFacilityData = isSuperUser ? facility : primaryFacilityData

      if (defaultF) {
        await mergePatchFacility({
          facility: defaultF,
          patch: writeableFacilityData,
        })
      } else {
        await createFacility({
          facility,
          orgId: organization.id,
        })
      }
      await onSave()
    } catch (e) {
      setError(e)
    }
  }

  const onErrors = (errors: object) => {
    // UI Controller may not be focused automatically
    // So need to focus here
    const keys = Object.keys(errors)
    if (keys.length) {
      highlightErrorInput(keys[0])
    }
  }

  const title = isEditMode ? 'Edit Community' : 'Add Community'
  const paymentSubscription = watch(
    'paymentSubscription'
  ) as PaymentSubscription
  const showUnitCount = isPlatform(paymentSubscription?.product)
  const subscriptionIsRequired = !isEmptyObject(paymentSubscription)
  const { paymentCustomer } = organization
  const setTodayAsStartDate =
    paymentCustomer?.stripeSubscriptionId &&
    paymentCustomer?.billingStrategy ===
      PaymentBillingStrategy.PAYMENT_BILLING_STRATEGY_PER_ORGANIZATION
  const showPaymentSubscriptionSection =
    isSuperUser && paymentCustomer?.stripeCustomerId
  const timeZoneOptions = getTimeZoneOptions(
    country || getCountryCode(getValues('timeZone'))
  )
  const emarSettingsIsActive = watch('emarSettings.isActive') === true
  const billingSettingsIsActive = watch('billingSettings.isActive') === true
  const careAppSettingsIsActive = watch('careAppSettings.isActive') === true
  const canSeeCareAppCheckbox = isFeatureAllowed(
    user,
    FeatureFlagNames.CARE_APP
  )

  return (
    <AnimatedPopup title={title}>
      <form className="mt-[32px]">
        <div className="mt-[32px]">
          <LabelAboveInput
            htmlFor="name"
            subLabel={requiredLabel(Boolean(errors.name))}
          >
            Name
          </LabelAboveInput>
          <BasicInput {...register('name', { required: true })} />
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput
            htmlFor="address"
            subLabel={requiredLabel(Boolean(errors.address))}
          >
            Address
          </LabelAboveInput>
          <Controller
            name="address"
            control={control}
            render={({ field: { value } }) => (
              <BasicInput
                name="address"
                defaultValue={formatSingleLineAddress(value)}
                placeholder="Enter Street, City, Zip"
                ref={inputRef}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value?.trim()
                  setAddressPlainText(value)
                  setValue('address.text', value)
                }}
              />
            )}
            rules={{ required: true }}
          />
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput
            htmlFor="input-timeZone"
            subLabel={requiredLabel(Boolean(errors.timeZone))}
          >
            Timezone
          </LabelAboveInput>
          <Controller
            control={control}
            name="timeZone"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <CreatableStyledSelect
                id="timeZone"
                instanceId="timeZone"
                name="timeZone"
                onChange={(opt?: { value: any }) =>
                  onChange(opt?.value || null)
                }
                options={timeZoneOptions}
                placeholder="Select a timezone..."
                value={
                  timeZoneOptions.find((opt) => opt.value === value) || null
                }
                isClearable={false}
              />
            )}
          />
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput htmlFor="licenseNumber">
            License Number
          </LabelAboveInput>
          <BasicInput {...register('stateLicenseNumber')} />
        </div>
        <Controller
          name="telecom"
          control={control}
          render={({ field: { onChange, value: telecom = [] } }) => {
            const onTelecomChange = (t?: ContactPoint[]) => {
              // Convert empty array to undefined
              return onChange(t && t.length ? t : undefined)
            }
            return (
              <>
                <div className="mt-[32px]">
                  <LabelAboveInput
                    htmlFor="email"
                    subLabel={requiredWhenError(Boolean(errors.telecom))}
                    errorMessage="Invalid format"
                  >
                    Email
                  </LabelAboveInput>
                  <EmailInput
                    inputProps={{
                      name: 'email',
                      onChange: getOnEmailChange({ telecom, onTelecomChange }),
                      defaultValue: getEmailValue(telecom),
                    }}
                  />
                </div>
                <div className="mt-[32px]">
                  <LabelAboveInput htmlFor="phone">Phone</LabelAboveInput>
                  <PhoneInput
                    inputProps={{
                      name: 'phone',
                      onChange: getOnPhoneChange({
                        onTelecomChange,
                        telecom,
                        use: ContactPoint_ContactPointUse.CONTACT_POINT_USE_WORK,
                      }),
                      value: getPhoneValue(telecom),
                    }}
                  />
                </div>
                <div className="mt-[32px]">
                  <LabelAboveInput htmlFor="fax">Fax</LabelAboveInput>
                  <FaxInput
                    inputProps={{
                      name: 'fax',
                      onChange: getOnFaxChange({ telecom, onTelecomChange }),
                      value: getFaxValue(telecom),
                    }}
                  />
                </div>
              </>
            )
          }}
        />
        <div className="mt-[32px]">
          <LabelAboveInput
            htmlFor="ombudsman"
            subLabel={requiredLabel(Boolean(errors.ombudsman))}
          >
            Ombudsman
          </LabelAboveInput>
          <Controller
            name="ombudsman"
            control={control}
            render={({ field: { onChange, value: ombudsman = {} } }) => {
              return (
                <PhoneInput
                  inputProps={{
                    name: 'ombudsman',
                    onChange: getOnOmbudsmanPhoneChange({
                      ombudsman,
                      onChange,
                      use: ContactPoint_ContactPointUse.CONTACT_POINT_USE_WORK,
                    }),
                    placeholder: 'Phone Number',
                    value: getPhoneValue(ombudsman?.telecom),
                  }}
                />
              )
            }}
            rules={{ required: true }}
          />
        </div>
        {showPaymentSubscriptionSection && (
          <>
            <div className="mt-[32px]">
              <LabelAboveInput
                htmlFor="paymentSubscription.startDate"
                subLabel={
                  subscriptionIsRequired
                    ? requiredLabel(
                        Boolean(errors.paymentSubscription?.startDate)
                      )
                    : undefined
                }
              >
                Subscription Start Date
              </LabelAboveInput>
              <Controller
                name="paymentSubscription.startDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    selected={value && fromDateMessageToDate(value)}
                    onChange={(v: Date) =>
                      onChange(v ? fromDateToDateMessage(v) : null)
                    }
                    customInput={<CalendarInput />}
                    maxDate={setTodayAsStartDate ? new Date() : undefined}
                    minDate={new Date()}
                  />
                )}
                rules={{ required: subscriptionIsRequired }}
              />
            </div>
            <div className="mt-[32px]">
              <LabelAboveInput
                htmlFor="paymentSubscription.product"
                subLabel={
                  subscriptionIsRequired
                    ? requiredLabel(
                        Boolean(errors.paymentSubscription?.product)
                      )
                    : undefined
                }
              >
                payment Product
              </LabelAboveInput>
              <Controller
                control={control}
                name="paymentSubscription.product"
                rules={{ required: subscriptionIsRequired }}
                render={({ field: { onChange, value } }) => (
                  <StyledSelect
                    id="productSelect"
                    instanceId="productSelect"
                    name="paymentSubscription.product"
                    onChange={(opt?: { value: PaymentProduct }) =>
                      onChange(opt?.value || null)
                    }
                    options={ProductOptions}
                    placeholder="Select a product..."
                    value={ProductOptions.find((opt) => opt.value === value)}
                    isClearable
                  />
                )}
              />
            </div>
            {showUnitCount && (
              <div className="mt-[32px]">
                <LabelAboveInput
                  htmlFor="paymentSubscription.unitCount"
                  subLabel={
                    subscriptionIsRequired
                      ? requiredLabel(
                          Boolean(errors.paymentSubscription?.unitCount)
                        )
                      : undefined
                  }
                >
                  Base Resident Count
                </LabelAboveInput>
                <BasicInput
                  {...register('paymentSubscription.unitCount', {
                    required: subscriptionIsRequired,
                  })}
                  type="number"
                />
              </div>
            )}
            <MoneyInReactHookForm
              uppercaseTitle
              control={control}
              holderClassName="mt-[32px]"
              id="paymentSubscription.unitAmount"
              inputProps={{
                name: 'paymentSubscription.unitAmount',
              }}
              name="paymentSubscription.unitAmount"
              title="Unit Amount"
            />
          </>
        )}
        {isSuperUser && (
          <div
            className={tw`mt-4 flex flex-col gap-2 border-t border-secondary-11 pt-4`}
          >
            <div className={tw`text-[12px] font-medium text-secondary-07`}>
              Communities will remain in training mode until the Go Live Date is
              set and passed.
            </div>
            <div className={tw`flex flex-row items-center gap-4`}>
              <BasicCheckbox
                data-testid="emar-active-toggle"
                {...register('emarSettings.isActive')}
                labelClassName={tw`mb-0`}
              >
                eMAR is active
              </BasicCheckbox>
              <ControlledLabelledCalendarInput<FacilityForm, ValidDate>
                control={control}
                name={'emarSettings.goLiveMedPassDate'}
                convertToSavedValue={fromDateToDateMessage}
                convertToSelectedValue={fromDateMessageToDate}
                inputProps={{
                  isClearable: true,
                  label: 'Go Live Date',
                  iconName: 'fa-calendar-heart',
                  disabled: !emarSettingsIsActive,
                }}
              />
            </div>
            <div className={tw`text-[12px] font-medium text-secondary-07`}>
              Enabling Billing will remove the resident-level Billing tab from
              the application.
            </div>
            <div className={tw`flex flex-row items-center gap-4`}>
              <BasicCheckbox
                data-testid="billing-active-toggle"
                {...register('billingSettings.isActive')}
                labelClassName={tw`mb-0`}
              >
                Billing is active
              </BasicCheckbox>
              <ControlledLabelledCalendarInput<FacilityForm, ValidDate>
                control={control}
                name={'billingSettings.goLiveDate'}
                convertToSavedValue={fromDateToDateMessage}
                convertToSelectedValue={fromDateMessageToDate}
                inputProps={{
                  isClearable: true,
                  label: 'Go Live Date',
                  iconName: 'fa-calendar-heart',
                  disabled: !billingSettingsIsActive,
                }}
              />
            </div>
            {canSeeCareAppCheckbox && (
              <>
                <div className={tw`text-[12px] font-medium text-secondary-07`}>
                  Enable Care App
                </div>
                <div className={tw`flex flex-row items-center gap-4`}>
                  <BasicCheckbox
                    data-testid="careapp-active-toggle"
                    {...register('careAppSettings.isActive')}
                    labelClassName={tw`mb-0`}
                  >
                    Care App is active
                  </BasicCheckbox>
                  <ControlledLabelledCalendarInput<FacilityForm, ValidDate>
                    control={control}
                    name={'careAppSettings.goLiveDate'}
                    convertToSavedValue={fromDateToDateMessage}
                    convertToSelectedValue={fromDateMessageToDate}
                    inputProps={{
                      isClearable: true,
                      label: 'Go Live Date',
                      iconName: 'fa-calendar-heart',
                      disabled: !careAppSettingsIsActive,
                    }}
                  />
                </div>
              </>
            )}
            <div className={tw`text-[12px] font-medium text-secondary-07`}>
              Test communities are excluded from Insights
            </div>
            <div className={tw`flex flex-row items-center gap-4`}>
              <BasicCheckbox
                data-testid="testcommunity-active-toggle"
                {...register('hellosignTestMode')}
                labelClassName={tw`mb-0`}
              >
                Is test community
              </BasicCheckbox>
            </div>
          </div>
        )}
        <div className={'mt-[32px]'}>
          <AnimatedPopupFormFooter
            yesBtn={{
              props: {
                onClick: handleSubmit(onSubmit, onErrors),
                id: 'saveFacilityButton',
              },
            }}
            noBtn={{
              label: 'Cancel',
              action: onClose,
              props: { id: 'cancelFacilityButton' },
            }}
            formState={formState}
          />
        </div>
      </form>
    </AnimatedPopup>
  )
}
