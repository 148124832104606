import {
  AugustInitialAppraisal,
  CustomDetailsWrapper,
} from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import { ServicePlan_PlanCategoryKey } from '@augusthealth/models/com/august/protos/service_plan'
import {
  AppraisalSettings_AppraisalCategory as AppraisalCategory,
  AppraisalSettings_AppraisalCategoryDetail,
  AppraisalSettings_DentalDetails,
  AppraisalSettings_HearingDetails,
  AppraisalSettings_Level,
  AppraisalSettings_MobilityDetails,
  AppraisalSettings_VisionDetails,
} from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { sortBy } from 'lodash'
import { FrontendDetail } from '@shared/types/assessment'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import {
  getDetailsPath,
  getLevelPath,
  getNotesPath,
  matchOnCategoryAndCustomKey,
  selectedCustomDetailForCategory,
} from '@shared/utils/residentAssessment'
import { patchResidentAssessment } from '@app/api/resident_assessment'
import { AssessmentChange } from './AssessmentPage/types'

export const residentAssessmentOrServicePlanPath =
  '/orgs/:orgId/facilities/:facilityId/(prospects|residents)/:personId/tasks/:taskId/:stepNumber?'

/**
 * Given a list of Assessment Changes and an assessment (aka august initial appraisal), construct
 * a patch that represents the assessment after all the changes
 * @param changes
 * @param initialAppraisal
 */
export function buildPatchFromChanges({
  changes,
  initialAssessment,
}: {
  changes: AssessmentChange[]
  initialAssessment: AugustInitialAppraisal
}) {
  return changes.reduce((assessmentToModify, change) => {
    let patch: AugustInitialAppraisal = {}
    if (change.tag === 'AssessmentReasonChange') {
      return {
        assessmentReason: {
          assessmentReason: change.assessmentReason.assessmentReason,
          otherReason: change.assessmentReason.otherReason ?? null,
        },
      }
    }

    const detailsPath = getDetailsPath(change.category)
    const detailsForCategory: string[] = assessmentToModify[detailsPath] ?? []
    const isCustomCategory =
      change.category.categoryKey ===
      ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_CUSTOM

    switch (change.tag) {
      case 'AddUniversal':
        if (change.detail.tag === 'DetailWithDropdown') {
          const isCustom = change.chosen?.customDetails !== undefined

          if (isCustom) {
            patch = makePatchAddCustom({
              assessmentToModify,
              ...change,
              detailsPath,
              detailsForCategory,
            })
          } else {
            // Checkbox with dropdown either enabled or a new option chosen
            patch = makePatchAddSingle({
              detailsPath,
              detailsForCategory,
              ...change,
              assessmentToModify,
            })
          }
        } else if (
          change.detail.tag === 'DetailWithCheckbox' ||
          change.detail.tag === 'DetailWithTextbox' ||
          change.detail.tag === 'DetailWithTextarea'
        ) {
          const isCustom = !!change.detail.value.customDetails

          if (isCustom) {
            patch = makePatchAddCustom({
              assessmentToModify,
              ...change,
              detailsPath,
              detailsForCategory,
            })
          } else {
            // Single non-custom checkbox clicked
            patch = {
              [detailsPath]: [
                ...detailsForCategory,
                change.detail.value[detailsPath],
              ],
            }
          }
        }
        break
      case 'RemoveUniversal':
        if (change.detail.tag === 'DetailWithDropdown') {
          const options = change.detail.value.options
          const hasCustomOption = options.some((o) => o.customDetails)
          if (hasCustomOption) {
            patch = makePatchRemoveCustom({
              assessmentToModify,
              ...change,
            })
          }
          if (detailsPath !== 'customDetails') {
            // Assuming that when path is 'customDetails', we will only have Custom Options, so calling makePatchRemoveCustom should be enough;
            // Also, if detailsPath === 'customDetails', detailsForCategory will be CustomDetailsWrapper[] rather string[], therefore code inside detailsForCategory.filter(...) will not work as expect.
            const pathsInOptions = options.map((o) => o[detailsPath])
            patch = {
              ...patch,
              [detailsPath]: [
                ...detailsForCategory.filter(
                  (d) => !pathsInOptions.includes(d)
                ),
              ],
            }
          }
        } else if (
          change.detail.tag === 'DetailWithCheckbox' ||
          change.detail.tag === 'DetailWithTextbox' ||
          change.detail.tag === 'DetailWithTextarea'
        ) {
          const isCustom = !!change.detail.value.customDetails

          if (isCustom) {
            patch = makePatchRemoveCustom({
              assessmentToModify,
              ...change,
            })
          } else {
            // Single non-custom checkbox clicked
            patch = {
              [detailsPath]: detailsForCategory.filter(
                (d) => d !== change.detail.value[detailsPath]
              ),
            }
          }
        }
        break
      case 'NeedChange':
        if (isCustomCategory) {
          patch = makePatchCustomLevel({ assessmentToModify, ...change })
        } else {
          patch = {
            [getLevelPath(change.category)]: change.level,
          }
        }
        break
      case 'NoteChange':
        if (isCustomCategory) {
          patch = makePatchCustomNote({ assessmentToModify, ...change })
        } else {
          patch = {
            [getNotesPath(change.category)]: change.note,
          }
        }
        break
    }

    return { ...assessmentToModify, ...patch }
  }, initialAssessment)
}

export async function updateAssessment({
  person,
  assessment,
  initialPromise,
  changes,
}: {
  person: Person
  assessment: AugustInitialAppraisal
  initialPromise?: Promise<Snapshot>
  changes: AssessmentChange[]
}): Promise<Snapshot> {
  let assessmentToModify = assessment
  if (initialPromise) {
    const response = (await initialPromise)?.data?.augustInitialAppraisal
    if (response) {
      assessmentToModify = response
    }
  }

  const patch = buildPatchFromChanges({
    changes: changes,
    initialAssessment: assessmentToModify,
  })
  const result = await patchResidentAssessment({ person, patch })

  return { data: { augustInitialAppraisal: result.appraisal } }
}

export function getOrderedCategoriesByStep({
  categories,
  step,
}: {
  categories: AppraisalCategory[]
  step: number
}) {
  return getOrderedCategories({ categories }).filter(
    (c) => c.categoryOptions?.page === step
  )
}

export function getOrderedCategories({
  categories,
}: {
  categories: AppraisalCategory[]
}) {
  // Lexicographic sort on page & displayOrder
  return sortBy(
    categories,
    (c) => `${c.categoryOptions?.page}${c.categoryOptions?.displayOrder}`
  )
}

export function getPageByCategory(category: AppraisalCategory) {
  return category.categoryOptions?.page
}

function makePatchRemoveCustom({
  assessmentToModify,
  category,
  detail,
}: {
  assessmentToModify: AugustInitialAppraisal
  category: AppraisalCategory
  detail: FrontendDetail
}) {
  const { currentDetailForCategory, otherCustomDetails, currentCustomDetails } =
    getCustomDetailsForPatching({
      assessmentToModify,
      category,
    })

  if (detail.tag === 'DetailWithDropdown') {
    return {
      customDetails: [
        ...otherCustomDetails,
        {
          ...currentDetailForCategory,
          fields: [
            ...currentDetailForCategory.fields.filter(
              (f) => !detail.value.options.map((o) => o.id).includes(f.id)
            ),
          ],
        },
      ],
    }
  }

  const newSelectedIds = currentDetailForCategory.fields.filter(
    (f) => f.id !== detail.value.id
  )

  const newCustomDetails = currentCustomDetails.map((cd) => {
    if (matchOnCategoryAndCustomKey(cd, category)) {
      return {
        ...currentDetailForCategory,
        fields: newSelectedIds,
      }
    }

    return cd
  })

  return { customDetails: newCustomDetails }
}

function makePatchAddCustom({
  assessmentToModify,
  category,
  detail,
  chosen,
  text,
  detailsPath,
  detailsForCategory,
}: {
  assessmentToModify: AugustInitialAppraisal
  category: AppraisalCategory
  detail: FrontendDetail
  chosen?: AppraisalSettings_AppraisalCategoryDetail
  text?: string
  detailsPath: string
  detailsForCategory: string[]
}) {
  const { currentDetailForCategory, otherCustomDetails } =
    getCustomDetailsForPatching({
      assessmentToModify,
      category,
    })

  if (detail.tag === 'DetailWithDropdown') {
    if (chosen === undefined) {
      throw new Error()
    }

    return {
      // Remove enum-backed details for the dropdown, since we're adding a custom one
      [detailsPath]: [
        ...detailsForCategory.filter(
          (d) => !detail.value.options.map((o) => o[detailsPath]).includes(d)
        ),
      ],
      customDetails: [
        ...otherCustomDetails,
        {
          ...currentDetailForCategory,
          fields: [
            ...currentDetailForCategory.fields.filter(
              (f) => !detail.value.options.map((o) => o.id).includes(f.id)
            ),
            {
              id: chosen.id!,
              selectedField: {
                value: chosen.id,
              },
            },
          ],
        },
      ],
    }
  }

  if (
    detail.tag === 'DetailWithTextbox' ||
    detail.tag === 'DetailWithTextarea'
  ) {
    if (text === undefined || text.length === 0) {
      throw new Error()
    }

    return {
      customDetails: [
        ...otherCustomDetails,
        {
          ...currentDetailForCategory,
          fields: [
            ...currentDetailForCategory.fields.filter(
              (f) => f.id !== detail.value.id
            ),
            {
              id: detail.value.id,
              textField: {
                value: text,
              },
            },
          ],
        },
      ],
    }
  }

  return {
    customDetails: [
      ...otherCustomDetails,
      {
        ...currentDetailForCategory,
        fields: [
          ...currentDetailForCategory.fields.filter(
            (f) => f.id !== detail.value.id
          ),
          {
            id: detail.value.id!,
            selectedField: {
              value: detail.value.id,
            },
          },
        ],
      },
    ],
  }
}

function makePatchCustomLevel({
  assessmentToModify,
  category,
  level,
}: {
  assessmentToModify: AugustInitialAppraisal
  category: AppraisalCategory
  level: AppraisalSettings_Level
}) {
  const { currentDetailForCategory, otherCustomDetails } =
    getCustomDetailsForPatching({
      assessmentToModify,
      category,
    })

  return {
    customDetails: [
      ...otherCustomDetails,
      {
        ...currentDetailForCategory,
        level: level,
      },
    ],
  }
}

function makePatchCustomNote({
  assessmentToModify,
  category,
  note,
}: {
  assessmentToModify: AugustInitialAppraisal
  category: AppraisalCategory
  note: string
}) {
  const { currentDetailForCategory, otherCustomDetails } =
    getCustomDetailsForPatching({
      assessmentToModify,
      category,
    })

  return {
    customDetails: [
      ...otherCustomDetails,
      {
        ...currentDetailForCategory,
        notes: note,
      },
    ],
  }
}

function makePatchAddSingle({
  detail,
  chosen,
  detailsPath,
  detailsForCategory,
  assessmentToModify,
  category,
}: {
  detail: FrontendDetail
  chosen?: AppraisalSettings_AppraisalCategoryDetail
  detailsPath: string
  detailsForCategory: string[]
  assessmentToModify: AugustInitialAppraisal
  category: AppraisalCategory
}) {
  if (detail.tag !== 'DetailWithDropdown' || chosen === undefined) {
    throw new Error()
  }

  return {
    [detailsPath]: [
      ...detailsForCategory.filter(
        (d) => !detail.value.options.map((o) => o[detailsPath]).includes(d)
      ),
      chosen[detailsPath],
    ],
    // Remove any previously selected custom details for this dropdown, since we've chosen an enum-backed one
    ...makePatchRemoveCustom({
      assessmentToModify,
      category,
      detail,
    }),
  }
}

export function getFallRiskPrintDisplay(
  appraisal?: Partial<AugustInitialAppraisal>
) {
  if (appraisal && appraisal.mobilityDetails) {
    return appraisal.mobilityDetails.includes(
      AppraisalSettings_MobilityDetails.MOBILITY_DETAILS_ASSISTANCE_WITH_OBSERVATION_FALL_MANAGEMENT
    )
      ? 'Yes'
      : 'No'
  }

  return 'Unknown'
}

export function getElopementRiskPrintDisplay(
  appraisal?: Partial<AugustInitialAppraisal>
) {
  if (appraisal && appraisal.wanderingAndElopement) {
    switch (appraisal.wanderingAndElopement) {
      case AppraisalSettings_Level.LEVEL_MAX:
        return 'Max'
      case AppraisalSettings_Level.LEVEL_MODERATE:
        return 'Moderate'
      case AppraisalSettings_Level.LEVEL_MINIMAL:
        return 'Minimal'
      case AppraisalSettings_Level.LEVEL_NONE:
        return 'None'
      default:
        return 'Unknown'
    }
  }

  return 'Unknown'
}

export function getGlassesPrintDisplay(
  appraisal?: Partial<AugustInitialAppraisal>
) {
  if (appraisal && appraisal.visionDetails) {
    return appraisal.visionDetails.includes(
      AppraisalSettings_VisionDetails.VISION_DETAILS_WEARS_GLASSES_REGULARLY
    )
      ? 'Yes'
      : 'No'
  }

  return 'No'
}

export function getHearingAidPrintDisplay(
  appraisal?: Partial<AugustInitialAppraisal>
) {
  if (appraisal && appraisal.hearingDetails) {
    if (
      appraisal.hearingDetails.includes(
        AppraisalSettings_HearingDetails.HEARING_DETAILS_WEARS_AIDS_IN_BOTH_EARS
      )
    ) {
      return 'Both'
    } else if (
      appraisal.hearingDetails.includes(
        AppraisalSettings_HearingDetails.HEARING_DETAILS_WEARS_AID_IN_LEFT_EAR_ONLY
      )
    ) {
      return 'Left'
    } else if (
      appraisal.hearingDetails.includes(
        AppraisalSettings_HearingDetails.HEARING_DETAILS_WEARS_AID_IN_RIGHT_EAR_ONLY
      )
    ) {
      return 'Right'
    }
  }

  return 'Unknown'
}

export function getDenturesPrintDisplay(
  appraisal?: Partial<AugustInitialAppraisal>
) {
  if (appraisal && appraisal.dentalDetails) {
    if (
      appraisal.dentalDetails.includes(
        AppraisalSettings_DentalDetails.DENTAL_DETAILS_WEARS_DENTURES
      )
    ) {
      return 'Yes'
    } else if (
      appraisal.dentalDetails.includes(
        AppraisalSettings_DentalDetails.DENTAL_DETAILS_WEARS_A_PARTIAL
      )
    ) {
      return 'Partial'
    }

    return 'No'
  }

  return 'Unknown'
}

const getCustomDetailsForPatching = ({
  assessmentToModify,
  category,
}: {
  assessmentToModify: AugustInitialAppraisal
  category: AppraisalCategory
}): {
  currentCustomDetails: CustomDetailsWrapper[]
  otherCustomDetails: CustomDetailsWrapper[]
  currentDetailForCategory: Required<
    Pick<CustomDetailsWrapper, 'categoryKey' | 'fields'>
  >
} => {
  const currentCustomDetails = assessmentToModify.customDetails ?? []
  return {
    currentCustomDetails: assessmentToModify.customDetails ?? [],
    otherCustomDetails: currentCustomDetails.filter(
      (cd) => !matchOnCategoryAndCustomKey(cd, category)
    ),
    currentDetailForCategory: selectedCustomDetailForCategory(
      assessmentToModify,
      category
    ),
  }
}
