import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import {
  hasPermissionForPerson,
  PersonPermissionGate,
} from '@shared/components/PermissionGates/PermissionGates'
import SearchBox from '@shared/components/SearchBox'
import { useUserContext } from '@shared/contexts/UserContext'
import { Facility } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { isCaliforniaFacility } from '@shared/utils/facilities'
import { tw } from '@shared/utils/tailwind'
import { DownloadMenu } from '@app/components/DownloadMenu'
import HUD from '@app/components/HUD'
import PersonPageTitle from '@app/components/PersonPageTitle'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import PersonContext from '@app/contexts/PersonContext'
import useMedications from '@app/hooks/useMedications'
import { NoteCardSkeleton } from '@app/pages/Notes/NoteCardSkeleton'
import styles from './styles.module.css'
import MedicationLogModal from './MedicationLogModal'
import MedListModal from './MedListModal'
import Orders from './Orders/Orders'
import { DownloadIndividualMarModal } from './PaperMar/DownloadMarModal'

interface Props {
  match: {
    params: {
      facilityId: string
      orgId: string
      id: string
    }
  }
}

interface LocationData {
  statusChange?: 'Discontinued' | 'Added' | 'Deleted' | 'Saved'
  highlightId?: string
}

type MedModal =
  | { tag: 'CentralMedLog' }
  | { tag: 'DestructionMedLog' }
  | { tag: 'MedList' }
  | { tag: 'PaperMAR'; blank: boolean }

export default function Medications({ match }: Props) {
  const history = useHistory()
  const { facilityId } = match.params
  const location = useLocation<LocationData>()
  const showHUD = location.state?.statusChange
  const highlightId = location.state?.highlightId

  const { facilities = [] } = useContext(FacilitiesContext)
  const { person } = useContext(PersonContext)
  const { user } = useUserContext()
  const { rxOrders, vitalOrders, reloadMedications } = useMedications()
  const [showMedModal, setShowMedModal] = useState<MedModal>()
  const [medFilter, setMedFilter] = useState('')
  const facility: Facility | undefined = facilities.find(
    (facility: Facility) => {
      return facility.id === facilityId
    }
  )

  const pageTitle = 'Orders'

  const clearLocationStatus = () => {
    history.replace({
      ...history.location,
      state: {
        highlightId,
      },
    })

    setTimeout(() => {
      clearLocationState()
    }, 15000)
  }

  const clearLocationState = () => {
    history.replace({ ...history.location, state: {} })
  }

  useEffect(() => {
    if (highlightId && !showHUD) {
      clearLocationState()
    }
  }, [])

  if (
    !person ||
    !facility ||
    rxOrders.tag === 'Loading' ||
    vitalOrders.tag === 'Loading'
  ) {
    return (
      <>
        <PersonPageTitle title={pageTitle} />
        <NoteCardSkeleton />
      </>
    )
  }

  const disableMenuItems =
    rxOrders.tag !== 'Complete' ||
    !person ||
    !facility ||
    rxOrders.value.length === 0

  const medMenuItems = getMedMenuItems({
    user,
    person,
    facility,
    disableMenuItems,
    setShowMedModal,
  })

  return (
    <div className={'mb-[32px]'}>
      {showHUD && (
        <HUD onExpire={clearLocationStatus}>Medication {showHUD}</HUD>
      )}
      <PersonPageTitle title={pageTitle} withBorder={false}>
        <div className={tw`flex`}>
          <SearchBox
            holderClassName={tw`mr-[12px]`}
            onChange={setMedFilter}
            value={medFilter}
          />
          <PersonPermissionGate
            person={person}
            permissions={[GroupPermission.GROUP_PERMISSION_MEDICATION_CREATE]}
          >
            <AsyncIconButton
              buttonStyle="secondary-fill"
              className={styles.medDropdownButton}
              id="addMedication"
              initialIcon="fa-prescription-bottle-alt"
              onClick={() => history.push('medications/new')}
            >
              Add Order
            </AsyncIconButton>
          </PersonPermissionGate>
          <DownloadMenu
            menuProps={{
              backgroundProps: {
                dataCy: 'med-menu-background',
              },
              buttonProps: {
                id: 'medDownloadMenu',
                classNames: 'ml-[12px]',
              },
            }}
            menuItems={medMenuItems}
          />
        </div>
      </PersonPageTitle>
      {showMedModal?.tag === 'PaperMAR' && (
        <DownloadIndividualMarModal
          user={user}
          person={person}
          onClose={() => setShowMedModal(undefined)}
        />
      )}
      {showMedModal?.tag === 'MedList' && (
        <MedListModal
          person={person}
          onClose={() => setShowMedModal(undefined)}
        />
      )}
      {showMedModal?.tag === 'CentralMedLog' && (
        <MedicationLogModal
          allMeds={rxOrders.value}
          person={person}
          setShowMedLog={() => setShowMedModal(undefined)}
          type="central"
          currentFacility={facility}
        />
      )}
      {showMedModal?.tag === 'DestructionMedLog' && (
        <MedicationLogModal
          allMeds={rxOrders.value}
          person={person}
          currentFacility={facility}
          setShowMedLog={() => setShowMedModal(undefined)}
          type="destruction"
        />
      )}
      {person && (
        <Orders
          medFilter={medFilter}
          person={person}
          rxOrders={rxOrders}
          vitalOrders={vitalOrders}
          reloadMedications={reloadMedications}
          history={history}
          highlightOrderId={highlightId}
        />
      )}
    </div>
  )
}

function getMedMenuItems({
  user,
  person,
  facility,
  setShowMedModal,
  disableMenuItems,
}: {
  user: UserAccount
  person: Person
  facility: Facility
  setShowMedModal: (modal: MedModal) => void
  disableMenuItems: boolean
}) {
  const hasPermission = (permission: GroupPermission): boolean => {
    return hasPermissionForPerson({
      user,
      person,
      permissions: [permission],
    })
  }

  const medLogSubtitle = isCaliforniaFacility(facility) ? ' (LIC622)' : ''

  return [
    {
      isDisabled: false,
      isVisible: hasPermission(GroupPermission.GROUP_PERMISSION_MAR_READ),
      id: 'printMarBtn',
      onClick: async () => setShowMedModal({ tag: 'PaperMAR', blank: false }),
      title: 'MAR',
    },
    {
      id: 'downloadMedList',
      isDisabled: disableMenuItems,
      isVisible: hasPermission(GroupPermission.GROUP_PERMISSION_MEDLIST_READ),
      onClick: async () => setShowMedModal({ tag: 'MedList' }),
      title: 'Med List',
    },
    {
      isDisabled: disableMenuItems,
      isVisible: hasPermission(GroupPermission.GROUP_PERMISSION_MEDLOG_READ),
      id: 'centralMedLog',
      onClick: async () => setShowMedModal({ tag: 'CentralMedLog' }),
      title: 'Medication Log' + medLogSubtitle,
    },
    {
      isDisabled: disableMenuItems,
      isVisible: hasPermission(GroupPermission.GROUP_PERMISSION_MEDLOG_READ),
      id: 'medDestructionLog',
      onClick: async () => setShowMedModal({ tag: 'DestructionMedLog' }),
      title: 'Medication Destruction Log' + medLogSubtitle,
    },
  ]
}
