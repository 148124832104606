import { match } from 'ts-pattern'
import { VerticalBarColors } from '@shared/components/VerticalRoundedBar'
import {
  RoutineAdministration,
  RoutineAdministrationProgressType,
} from '@shared/types/careapp'
import {
  routineAdminIsDone,
  routineAdminIsException,
  routineAdminIsNotStarted,
  routineAdminIsOverDue,
} from '@shared/utils/careapp'

export function getRoutineAdministrationMap(
  routineAdministrations: RoutineAdministration[],
  facilityTimeZone: string
) {
  return routineAdministrations.reduce(
    (hash, administration) => {
      return match(administration)
        .when(routineAdminIsDone, (administration) => ({
          ...hash,
          Done: [...hash.Done, administration],
        }))
        .when(routineAdminIsException, (administration) => ({
          ...hash,
          Exceptions: [...hash.Exceptions, administration],
        }))
        .when(
          (administration) =>
            routineAdminIsOverDue(administration, facilityTimeZone),
          (administration) => ({
            ...hash,
            Overdue: [...hash.Overdue, administration],
          })
        )
        .when(
          (administration) =>
            routineAdminIsNotStarted(administration, facilityTimeZone),
          (administration) => ({
            ...hash,
            'Not started yet': [...hash['Not started yet'], administration],
          })
        )
        .otherwise(() => hash)
    },
    {
      Done: [] as RoutineAdministration[],
      Exceptions: [] as RoutineAdministration[],
      Overdue: [] as RoutineAdministration[],
      'Not started yet': [] as RoutineAdministration[],
    }
  )
}

export function getIconNameAndClassByStatus(
  type: RoutineAdministrationProgressType
): {
  barColor: VerticalBarColors
  iconName: string
  iconClass: string
} {
  switch (type) {
    case 'Done':
      return {
        barColor: 'primary-light',
        iconName: 'circle-check',
        iconClass: 'text-primary-light',
      }
    case 'Overdue':
      return {
        barColor: 'tags-red',
        iconName: 'siren-on',
        iconClass: 'text-tags-red',
      }
    case 'Exceptions':
      return {
        barColor: 'pill-warning',
        iconName: 'circle-xmark',
        iconClass: 'text-pill-warning',
      }
    case 'Not started yet':
      return {
        barColor: 'mute',
        iconName: 'hourglass-start',
        iconClass: 'mute-text',
      }
  }
}
