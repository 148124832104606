import { PersonCard } from '@shared/components/LinkCard/PersonCard'
import { getRoutineAdministrationMap } from '@shared/components/RoutineAdministrationsStatusSummary/helpers'
import RoutineAdministrationsStatusSummary from '@shared/components/RoutineAdministrationsStatusSummary/RoutineAdministrationsStatusSummary'
import { CareAppPerson, RoutineAdministration } from '@shared/types/careapp'
import { tw } from '@shared/utils/tailwind'

export default function ResidentRoutinesCard({
  careAppPerson,
  routineAdministrations,
  facilityTimeZone,
}: {
  careAppPerson: CareAppPerson
  routineAdministrations: RoutineAdministration[]
  facilityTimeZone: string
}) {
  const {
    admissionsInformation: { bedDetails, roomDetails },
  } = careAppPerson

  return (
    <PersonCard
      cardClassName={tw`mb-[24px]`}
      anchorClassName={tw`text-gray-01`}
      href=""
      person={careAppPerson}
      roomInfo={{ bedDetails, roomDetails }}
    >
      <RoutineAdministrationsStatusSummary
        routineAdministrationMap={getRoutineAdministrationMap(
          routineAdministrations,
          facilityTimeZone
        )}
      />
    </PersonCard>
  )
}
