import Skeleton from 'react-loading-skeleton'
import { billingFeatureForFacility } from '@shared/utils/facilities'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import Billing from './Billing'
import PayerBilling from './PayerBilling'

export default function BillingDecider() {
  const { currentFacility } = useCurrentFacility()

  if (currentFacility === undefined || currentFacility.tag === 'Loading') {
    return <Skeleton />
  }

  return billingFeatureForFacility(currentFacility.value!) ===
    'Community-wide Billing' ? (
    <PayerBilling />
  ) : (
    <Billing />
  )
}
