import { FrontendDetail } from '@shared/types/assessment'
import { AugustFieldType } from '@shared/types/custom_field'
import { ADDED } from '@app/pages/Tools/AssessmentConfiguration/helpers'

export function isSupportedAugustField(detailEnum: unknown): boolean {
  return detailEnum === AugustFieldType.AUGUST_FIELD_TYPE_DIETARY_RESTRICTIONS
}

export function constructAugustFieldDetail({
  description,
  augustField,
  detailGroup,
}: {
  augustField: AugustFieldType
  description: string
  detailGroup: string
}): Partial<FrontendDetail> {
  return {
    tag: 'DetailWithAugustField',
    value: {
      augustFieldType: augustField,
      description,
      group: detailGroup,
    },
    tempId: ADDED,
  }
}
