import { CustomDetailsField } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import { AppraisalSettings_AppraisalCategory as AppraisalCategory } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { useEffect, useState } from 'react'
import { BasicCheckbox } from '@shared/components/BasicInput/BasicInput'
import { DetailWithCheckbox } from '@shared/types/assessment'
import { formatScore, getDetailsPath } from '@shared/utils/residentAssessment'
import { AssessmentChange } from '../types'

export function CheckboxDetail({
  detailData,
  category,
  selectedCustomDetails,
  selectedDetails,
  disabled,
  onChange,
  keyPrefix,
  selfUpdating = false,
}: {
  detailData: DetailWithCheckbox
  category: AppraisalCategory
  selectedCustomDetails: CustomDetailsField[]
  selectedDetails: string[]
  disabled: boolean
  onChange: (change: AssessmentChange) => void
  keyPrefix: string
  selfUpdating?: boolean
}) {
  const { value: backendDetail } = detailData
  const categoryKey = category.categoryKey
  const isCustom = !!backendDetail.customDetails
  let defaultChecked: boolean
  const changeHandler: (e: React.FormEvent<HTMLInputElement>) => void = (e) => {
    onChange({
      tag: e.currentTarget.checked ? 'AddUniversal' : 'RemoveUniversal',
      category,
      detail: detailData,
    })
  }

  if (isCustom) {
    defaultChecked = selectedCustomDetails.some(
      (scd) => scd.id === backendDetail.id
    )
  } else {
    const value = backendDetail[getDetailsPath(category)]
    defaultChecked = selectedDetails.includes(value)
  }

  const [checked, setChecked] = useState(defaultChecked)
  useEffect(() => {
    if (selfUpdating) {
      setChecked(defaultChecked)
    }
  }, [selectedCustomDetails, selectedDetails])

  // TODO: A controlled one for Claiborne?
  if (selfUpdating) {
    return (
      <div>
        <BasicCheckbox
          key={`${categoryKey}-${backendDetail.description}-controlled`}
          name={keyPrefix}
          disabled={disabled}
          checked={checked}
          onChange={changeHandler}
          labelClassName={'inline-flex'}
        >
          {backendDetail.description} {formatScore(backendDetail)}
        </BasicCheckbox>
      </div>
    )
  } else {
    return (
      <div>
        <BasicCheckbox
          key={`${categoryKey}-${backendDetail.description}`}
          name={keyPrefix}
          disabled={disabled}
          defaultChecked={defaultChecked}
          onChange={changeHandler}
          labelClassName={'inline-flex'}
        >
          {backendDetail.description} {formatScore(backendDetail)}
        </BasicCheckbox>
      </div>
    )
  }
}
