import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import {
  admissionTasksPathForPerson,
  billingPathForPerson,
  carePathForPerson,
  contactsPathForPerson,
  detailsPathForPerson,
  documentsPathForPerson,
  medicationsPathForPerson,
  notesPathForPerson,
} from '@shared/legacy_routes'
import { Facility } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { isResponsiblePerson } from '@shared/utils/user'
import RouterTabs, { TabListProps } from '@app/components/RouterTabs'

export function tabFromUrl(path: string): Tab | undefined {
  if (path.match(/medications/)) {
    return 'Medications'
  } else if (path.match(/billing/)) {
    return 'Billing'
  } else if (path.match(/notes/)) {
    return 'Notes'
  } else if (path.match(/incidents/)) {
    return 'Notes'
  } else if (path.match(/documents/)) {
    return 'Documents'
  } else if (path.match(/care/)) {
    return 'Care'
  } else if (path.match(/admission/)) {
    return 'Tasks'
  } else if (path.match(/contacts/)) {
    return 'Contacts'
  } else if (path.match(/details/)) {
    return 'Details'
  }

  return undefined
}

export type Tab =
  | 'Tasks'
  | 'Contacts'
  | 'Details'
  | 'Documents'
  | 'Care'
  | 'Medications'
  | 'Billing'
  | 'Notes'

interface Props {
  person: Person
  user: UserAccount
  activeTab: Tab
  facility: Facility
}

export default function Tabs({ person, activeTab, user }: Props) {
  const canAccessTasks = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_TASK_UPDATE],
  })

  const canAccessMedications = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_MEDICATION_READ],
  })

  const hasBillingPermissions = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_BILLING_READ],
  })
  const canAccessBilling = hasBillingPermissions

  const { orgId, facilityId, id } = person
  const params = { user, orgId, facilityId, personId: id }
  const canAccessDetails =
    hasPermissionForPerson({
      user,
      person,
      permissions: [
        GroupPermission.GROUP_PERMISSION_SNAPSHOT_READ,
        GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
        GroupPermission.GROUP_PERMISSION_PERSON_READ,
      ],
    }) && !isResponsiblePerson(params)

  const canAccessNotes = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_INCIDENT_READ],
  })
  const canAccessRoutine = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_ROUTINE_READ],
  })
  const canAccessContacts = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_PERSON_CONTACT_READ],
  })
  const canAccessDocuments = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_SNAPSHOT_READ],
  })

  const tabList: TabListProps = [
    canAccessTasks && {
      url: admissionTasksPathForPerson(person as Required<Person>),
      label: 'Tasks',
    },
    canAccessContacts && {
      url: contactsPathForPerson(person as Required<Person>),
      label: 'Contacts',
    },
    canAccessDetails && {
      url: detailsPathForPerson(person as Required<Person>),
      label: 'Details',
    },
    canAccessDocuments && {
      url: documentsPathForPerson(person as Required<Person>),
      label: 'Documents',
    },
    canAccessRoutine && {
      url: carePathForPerson(person as Required<Person>),
      label: 'Care',
    },
    canAccessMedications && {
      url: medicationsPathForPerson(person as Required<Person>),
      label: 'Medications',
    },
    canAccessNotes && {
      url: notesPathForPerson(person as Required<Person>),
      label: 'Notes',
    },
    canAccessBilling && {
      url: billingPathForPerson(person as Required<Person>),
      label: 'Billing',
    },
  ].filter((t) => t) as TabListProps

  return <RouterTabs activeTab={activeTab} tabList={tabList} />
}
