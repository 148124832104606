import { Option as EffectOption } from 'effect'

const NONE_KEY = 'NONE-7f5c02a2-fbf3-4633-9f86-16e7ad341de2'
export const NONE = { [NONE_KEY]: true }
export type None = typeof NONE
export type Option<T> = T | None

export function Option<T>(value: T | undefined | null): Option<T> {
  if (value === null || value === undefined) {
    return NONE
  } else {
    return value
  }
}

export function isNone<T>(value: Option<T>): value is None {
  return typeof value === 'object' && value && NONE_KEY in value
}

export function map<A, B>(option: Option<A>, fn: (a: A) => B): Option<B> {
  if (isNone(option)) {
    return NONE
  } else {
    return fn(option)
  }
}

export function getOrElse<A>(option: Option<A>, fallback: A): A {
  return isNone(option) ? fallback : option
}

export function findById<HasId extends { id?: string }>(
  id: string,
  hasIds: HasId[]
): Option<HasId> {
  return hasIds.find((hasId) => hasId.id === id) || NONE
}

export function toEffectOption<A>(option: Option<A>): EffectOption.Option<A> {
  return isNone(option) ? EffectOption.none() : EffectOption.some(option)
}

export function fromEffectOption<A>(option: EffectOption.Option<A>): Option<A> {
  return EffectOption.isNone(option) ? NONE : Option(option.value)
}
