import {
  OrgFacilitySettings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import { ServicePlan_PlanCategoryKey } from '@augusthealth/models/com/august/protos/service_plan'
import {
  AppraisalSettings_AnswerGroup,
  AppraisalSettings_AppraisalCategory,
  AppraisalSettings_AppraisalCategoryDetail,
} from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { cloneDeep } from 'lodash'
import {
  GroupedOptionTypeBase,
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { FrontendDetail } from '@shared/types/assessment'
import {
  AssessmentCategory,
  AssessmentCategoryOptions,
  AssessmentConfig,
  AssessmentLevel,
  AssessmentReason,
  CategoryKeyIdentifier,
  UpdatedDetailMap,
  UpdateDetailByPageMap,
} from '@shared/types/assessment_configuration'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import notEmpty from '@shared/utils/notEmpty'
import {
  configureSettingsId,
  SettingsConfig,
  SettingsLevel,
} from '@shared/utils/orgFacilitySettings'
import {
  buildCategoryKeyIdentifier,
  getCategoryAndCustomKeyFromCategoryKeyIdentifier,
  getCategoryTitle,
} from '@shared/utils/residentAssessment'
import { buildAssessmentReasonsForSubmission } from './AssessmentReasons/helpers'
import {
  buildAssessmentDetails,
  getListOfUpdatedDetails,
} from './DetailGroups/helpers'
import { buildAssessmentLevels, getListOfUpdatedLevels } from './Levels/helpers'

export const ADDED = 'ADDED'
export const REMOVED = 'REMOVED'
export const NEEDS = 'NEEDS'

export type FluidAssessmentCategory =
  | AssessmentCategory
  | AppraisalSettings_AppraisalCategory

/**
 * Creates an array of dropdown options that match the category to their page and display order
 *
 * @param categories The Appraisal Setting Categories of the assessment
 * @param originalCategories The Original Appraisal Setting Categories of the assessment - used to keep track of updated category title changes
 */
export const getAssessmentCategoryOptions = (
  categories: FluidAssessmentCategory[],
  originalCategories?: AppraisalSettings_AppraisalCategory[]
): GroupedOptionTypeBase<AssessmentCategory>[] => {
  const groupedByPage = categories.reduce((accum, category) => {
    const pageNumber = category.categoryOptions!.page!
    const matchingPage = accum[pageNumber]
    if (matchingPage) {
      accum[pageNumber].push(category)
    } else {
      accum = {
        ...accum,
        [pageNumber]: [category],
      }
    }

    return accum
  }, {})

  // sort categories by display order
  Object.keys(groupedByPage).forEach((page) => {
    groupedByPage[page].sort((a, b) => {
      const aDisplayOrder = a.categoryOptions.displayOrder
      const bDisplayOrder = b.categoryOptions.displayOrder

      return sortCompare(aDisplayOrder, bDisplayOrder)
    })
  })

  return Object.keys(groupedByPage).map((page) => ({
    label: `Page ${page}`,
    options: groupedByPage[page].map((opt) => {
      const matchingOriginalOption = originalCategories
        ? originalCategories.find(
            (cat) =>
              cat.categoryKey === opt.categoryKey &&
              cat.customKey === opt.customKey
          )
        : opt

      const optWithCorrectKey = {
        ...cloneDeep(opt),
        categoryKey: buildCategoryKeyIdentifier(opt),
      }
      optWithCorrectKey.categoryOptions.originalTitle =
        matchingOriginalOption?.categoryOptions?.title

      return {
        label: `${getCategoryTitle(opt)}`,
        value: optWithCorrectKey,
      }
    }),
  }))
}

const sortCompare = (a, b): number => {
  if (a < b) {
    return -1
  } else if (a > b) {
    return 1
  }
  return 0
}

const getListOfUpdatedCategoryOptions = (
  updatedOptions: AssessmentCategoryOptions,
  selectedCategory: AssessmentCategory
): UpdatedDetailMap => {
  const detailProperty = 'options'
  const updates: UpdatedDetailMap = {}

  updates[detailProperty] = []

  const originalOptions = selectedCategory.categoryOptions!

  if (updatedOptions.title !== originalOptions.originalTitle) {
    updates[detailProperty].push({
      type: 'option',
      valueChanged: 'category title',
      updated: updatedOptions.title!,
      original: originalOptions.originalTitle!,
    })
  }

  if (updatedOptions.question !== originalOptions.question) {
    updates[detailProperty].push({
      type: 'level',
      valueChanged: 'question',
      updated: updatedOptions.question!,
      original: originalOptions.question!,
      group: NEEDS,
    })
  }

  return updates
}

export const getPendingUpdates = ({
  selectedCategory,
  updatedDetails,
  updatedLevels,
  updatedCategoryOptions,
  assessmentConfig,
}: {
  selectedCategory: AssessmentCategory
  updatedDetails: Map<string, FrontendDetail[]>
  updatedLevels: AssessmentLevel[]
  updatedCategoryOptions: AssessmentCategoryOptions
  assessmentConfig: AssessmentConfig
}): UpdateDetailByPageMap => {
  const updates: UpdateDetailByPageMap = {}

  if (assessmentConfig[selectedCategory.categoryKey].deleteOnSave) {
    updates[selectedCategory.categoryKey] = [
      {
        type: 'deleteCategory',
        valueChanged: 'Category will be deleted',
        categoryTitle: updatedCategoryOptions.title,
      },
    ]
  } else {
    const detailChanges = getListOfUpdatedDetails(
      updatedDetails,
      buildAssessmentDetails(selectedCategory!),
      selectedCategory
    )

    const levelChanges = getListOfUpdatedLevels(
      updatedLevels,
      selectedCategory!.levels ?? []
    )

    const categoryOptionChanges = getListOfUpdatedCategoryOptions(
      updatedCategoryOptions,
      selectedCategory
    )

    Object.keys(categoryOptionChanges).forEach((option) => {
      if (updates[option]) {
        updates[option] = [...updates[option], ...categoryOptionChanges[option]]
      } else {
        updates[option] = [...categoryOptionChanges[option]]
      }
    })

    Object.keys(detailChanges).forEach((detail) => {
      updates[detail] = [...detailChanges[detail]]
    })

    Object.keys(levelChanges).forEach((level) => {
      if (updates[level]) {
        updates[level] = [...updates[level], ...levelChanges[level]]
      } else {
        updates[level] = [...levelChanges[level]]
      }
    })
  }

  return updates
}

type BuildSettings = {
  keepEmptyCategories?: boolean
  keepDeletedCategories?: boolean
}
export const buildOrgFacilitySettingsFromAssessmentConfig = (
  assessment: AssessmentConfig,
  settingsConfig: SettingsConfig,
  assessmentReasons: AssessmentReason[],
  options?: BuildSettings
): OrgFacilitySettings => {
  const keepEmptyCategories = options?.keepEmptyCategories ?? false
  const keepDeletedCategories = options?.keepDeletedCategories ?? false
  const appraisalSettings: AppraisalSettings_AppraisalCategory[] = Object.keys(
    assessment
  )
    .map((categoryKey: CategoryKeyIdentifier) => {
      const category = assessment[categoryKey]

      if (!keepDeletedCategories && category.deleteOnSave) {
        return null
      }

      const answerGroups: AppraisalSettings_AnswerGroup[] = []

      const details: AppraisalSettings_AppraisalCategoryDetail[] = []
      const customDetails: AppraisalSettings_AppraisalCategoryDetail[] = []

      let displayOrder = 1

      category.details.forEach((detailGroup) => {
        detailGroup.forEach((detail) => {
          if (detail.tempId === REMOVED) {
            return
          }

          if (detail.tag === 'DetailWithDropdown') {
            const relevantOptions = detail.value.options.filter(
              (opt) => opt.tempId !== REMOVED
            )

            relevantOptions.forEach((opt) => {
              const optCopy = cloneDeep(opt)

              optCopy.displayOrder = displayOrder
              displayOrder = displayOrder + 1

              delete optCopy.tempId
              delete optCopy.originalTempId

              if (opt.customDetails) {
                customDetails.push(optCopy)
              } else {
                details.push(optCopy)
              }
            })

            answerGroups.push(detail.value.detailMetadata)
          } else {
            const detailCopy = cloneDeep(detail.value)

            detailCopy.displayOrder = displayOrder
            displayOrder = displayOrder + 1

            delete detailCopy.tempId

            if (detail.value.customDetails) {
              customDetails.push(detailCopy)
            } else {
              details.push(detailCopy)
            }
          }
        })
      })

      const builtCategory: AppraisalSettings_AppraisalCategory = {
        categoryOptions: {
          ...category.categoryOptions,
          originalTitle: undefined,
        },
        isExcludedFromServicePlan: category.isExcludedFromServicePlan ?? false,
      }

      if (
        categoryKey.startsWith(
          ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_CUSTOM
        )
      ) {
        builtCategory.categoryKey =
          ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_CUSTOM
        builtCategory.customKey =
          getCategoryAndCustomKeyFromCategoryKeyIdentifier(
            categoryKey
          ).customKey
      } else {
        builtCategory.categoryKey = categoryKey as ServicePlan_PlanCategoryKey
      }

      if (category.levels.length > 0) {
        builtCategory.levels = category.levels
          .filter((level) => level.tempId !== REMOVED)
          .map((level) => {
            const levelCopy = cloneDeep(level)
            delete levelCopy.tempId
            return levelCopy
          })
      }

      if (details.length > 0) {
        builtCategory.details = details
      }

      if (answerGroups.length > 0) {
        builtCategory.answerGroups = answerGroups
      }

      if (customDetails.length > 0) {
        builtCategory.customDetails = customDetails
      }

      if (
        !keepEmptyCategories &&
        !builtCategory.details &&
        !builtCategory.answerGroups &&
        !builtCategory.customDetails &&
        !builtCategory.levels
      ) {
        // category exists in the Tools UI but is empty and will not appear in the rendered assessment so ignore it for submission
        return null
      }

      return builtCategory
    })
    .filter(notEmpty)

  return {
    ...configureSettingsId(settingsConfig),
    settingsType: SettingsType.SETTINGS_TYPE_APPRAISAL_SETTINGS,
    settings: {
      appraisalSettings: {
        categories: appraisalSettings,
        assessmentReasons:
          buildAssessmentReasonsForSubmission(assessmentReasons),
      },
    },
  }
}

export const facilityMatchesOrg = ({
  facility,
  organization,
}: {
  facility: OptionTypeBase<Facility> | null
  organization: OptionTypeBase<Organization>
}): boolean => {
  return !!(
    facility &&
    organization?.value &&
    facility.value.orgId === organization.value.id
  )
}

export const buildAssessmentConfig = (
  categories: FluidAssessmentCategory[]
): AssessmentConfig => {
  return categories.reduce((accum, cat) => {
    if (cat.categoryKey) {
      const key = buildCategoryKeyIdentifier({
        categoryKey: cat.categoryKey,
        customKey: cat.customKey,
      })

      accum[key] = {
        levels: cat.levels ? cloneDeep(buildAssessmentLevels(cat.levels!)) : [],
        details: new Map(buildAssessmentDetails(cat as AssessmentCategory)),
        categoryOptions: cat.categoryOptions,
        isExcludedFromServicePlan: cat.isExcludedFromServicePlan,
      }
    }

    return accum
  }, {}) as AssessmentConfig
}

const getHighestDisplayOrderForCategorySection = (
  categoryKey: string,
  currentDetails: Map<string, FrontendDetail[]>
) => {
  const matchingCategory = currentDetails.get(categoryKey)!

  return Math.max(...matchingCategory.map((detail) => detail.displayOrder))
}

export const getHighestDisplayOrderForCategory = (
  currentDetails: Map<string, FrontendDetail[]>
) => {
  return Math.max(
    ...Array.from(currentDetails.keys()).map((key) => {
      return getHighestDisplayOrderForCategorySection(key, currentDetails)
    })
  )
}

export const reorderExistingCategoriesWithNewCategory = ({
  newCategory,
  currentAssessmentConfig,
}: {
  newCategory: AssessmentCategory
  currentAssessmentConfig: AssessmentConfig
}): AssessmentConfig => {
  const updatedAssessment: AssessmentConfig = cloneDeep(currentAssessmentConfig)

  const desiredPage = newCategory.categoryOptions!.page!
  const categoriesOnSamePage: {
    key: string
    page: number
    displayOrder: number
  }[] = Object.keys(currentAssessmentConfig)
    .map((catKey) => ({
      key: catKey,
      page: currentAssessmentConfig[catKey].categoryOptions.page,
      displayOrder:
        currentAssessmentConfig[catKey].categoryOptions.displayOrder,
    }))
    .filter((cat) => cat.page === desiredPage)

  const sorted = [
    ...categoriesOnSamePage,
    {
      key: newCategory.categoryKey,
      page: desiredPage,
      displayOrder: newCategory.categoryOptions!.displayOrder!,
    },
  ]
    .sort((a, b) => {
      if (
        a.key === newCategory.categoryKey &&
        a.displayOrder === b.displayOrder
      ) {
        // prioritize the newly created category
        return -1
      }
      return sortCompare(a.displayOrder, b.displayOrder)
    })
    .map((cat, index) => ({
      ...cat,
      displayOrder: index + 1,
    }))

  sorted.forEach((cat) => {
    if (cat.key === newCategory.categoryKey) {
      updatedAssessment[cat.key] = {
        ...newCategory,
        categoryKey: newCategory.categoryKey,
        levels: [],
        details: new Map<string, FrontendDetail[]>(),
        categoryOptions: {
          ...newCategory.categoryOptions,
          displayOrder: cat.displayOrder,
        },
      }
    } else {
      updatedAssessment[cat.key] = {
        ...cloneDeep(currentAssessmentConfig[cat.key]),
        categoryOptions: {
          ...currentAssessmentConfig[cat.key].categoryOptions,
          displayOrder: cat.displayOrder,
        },
      }
    }
  })

  return updatedAssessment
}

export const hasRequiredInformationToSaveAssessment = (
  settingsConfig: SettingsConfig
): boolean => {
  const toApplyToAssessment = configureSettingsId(settingsConfig)

  switch (settingsConfig.settingsLevel) {
    case SettingsLevel.FACILITY_LEVEL:
      return !!(toApplyToAssessment.orgId && toApplyToAssessment.facilityId)
    case SettingsLevel.ORG_LEVEL:
      return !!toApplyToAssessment.orgId
    case SettingsLevel.ORG_STATE_LEVEL:
      return !!(toApplyToAssessment.orgId && toApplyToAssessment.state)
    case SettingsLevel.STATE_LEVEL:
      return !!toApplyToAssessment.state
    case SettingsLevel.GLOBAL_LEVEL:
      return true
    default:
      return false
  }
}
